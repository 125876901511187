import React, { useEffect, useState } from 'react';

import InsideHeader from '../../../components/InsideHeader/InsideHeader';
import PaginationCom from '../../../components/PaginationCom';
import DeleteModel from '../../../components/DeleteModel';
import SelectWithPagination from '../../../components/SelectWithPagination';
import ButtonCom from '../../../components/Button/ButtonCom';

import { Col, Input, Row, Table, Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

import { tableSortIconToggle } from '../../../utility/tableSorting';

import { ALBUM_SUB_CATEGORY } from '../../../service';

const AlbumCategory = ({ permissions }) => {
  const [albumSubCategory, setAlbumSubCategory] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [search, setSearch] = useState(null);

  const [subCategory, setSubCategory] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const navigate = useNavigate();

  const getAlbumSubCategory = async (
    page = 1,
    pageSize = 10,
    sort = null,
    subcategoryId = null,
    search = null
  ) => {
    setLoading(true);
    try {
      const res = await ALBUM_SUB_CATEGORY.GET_ALBUM_SUB_CATEGORY(
        pageSize,
        (page - 1) * pageSize,
        sort,
        subcategoryId,
        search
      );
      if (res.status === 1 || res.status === '1') {
        setAlbumSubCategory(res);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const loadOptionsOfSubCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUM_SUB_CATEGORY.GET_SUB_CATEGORY(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.subCategoryName,
        media_item: item?.media_item,
        subCategoryName: item?.subCategoryName,
        subtitle: item?.subtitle,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const deleteModel = async () => {
    try {
      const res = await ALBUM_SUB_CATEGORY.DELETE_ALBUM_SUB_CATEGORY(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        let page = currentPage;
        if (albumSubCategory?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          getAlbumSubCategory(page, pageSize, sort, subCategory?.value, search);
        }
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong !');
    }
  };

  const handleSorting = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  useEffect(() => {
    getAlbumSubCategory(
      currentPage,
      pageSize,
      sort,
      subCategory?.value,
      search
    );
  }, [currentPage, pageSize, search, sort, subCategory?.value]);

  const columns = [
    {
      title: 'Sub-Category',
      dataIndex: 'subcategory',
      key: 'subcategory',
      align: 'left',
      sortDirections: ['ascend', 'descend'],
      render: (subcategory) => subcategory?.subCategoryName,
      ellipsis: true,
    },
    {
      title: 'Album',
      dataIndex: 'album',
      key: 'album',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      render: (album) => album?.albumName,
      ellipsis: true,
    },
    {
      title: (column) =>
        tableSortIconToggle('Position No.', 'positionNo', column),
      dataIndex: 'positionNo',
      key: 'positionNo',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumSubCategory?.data?.length > 1 ? true : false,
      width: 170,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 170,
      render: (_, record) => {
        return (
          <div className='albumAction'>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Album Sub-Category'>
                <EditOutlined
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                  onClick={() =>
                    navigate(`/category/sub-category/album/edit/${record?.id}`)
                  }
                />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Album Sub-Category'>
                <DeleteOutlined
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                  onClick={() => {
                    setDeleteId(record.id);
                    setIsDeleteModalVisible(true);
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  if (permissions?.isUpdate === false && permissions?.isDelete === false) {
    delete columns[3];
  }

  return (
    <>
      <InsideHeader className='px-2 pt-2 pb-1'>
        <Row gutter={20} className='align-items-center'>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={11}
            xl={14}
            className='gutter-row mb-1'
          >
            <Input
              type='search'
              placeholder='Start typing to search Album'
              className='input-control'
              value={tempSearch}
              allowClear
              onChange={(e) => handleChangeOfSearch(e)}
              suffix={
                <SearchOutlined
                  style={{ color: '#8f9bb3' }}
                  onClick={handleSearch}
                />
              }
              onKeyPress={(e) => {
                if (e?.key === 'Enter' && e.target.value) {
                  handleSearch();
                }
              }}
            />
          </Col>
          {(permissions?.isCreate || permissions?.isCreate === undefined) && (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={13}
              xl={10}
              className='gutter-row mb-1'
            >
              <div className='d-flex flex-wrap align-items-center justify-content-md-left justify-content-end'>
                <ButtonCom
                  type='primary'
                  onClick={() => navigate('/category/sub-category/album/add')}
                >
                  Create Album Sub-Category
                </ButtonCom>
              </div>
            </Col>
          )}
        </Row>
      </InsideHeader>

      <InsideHeader className='mt-3'>
        <Row gutter={20} className='align-items-center'>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className='gutter-row'>
            <SelectWithPagination
              placeholder='Select Sub-Category'
              loadOptions={loadOptionsOfSubCategory}
              onChange={(e) => setSubCategory(e)}
              additional={{
                skip: 0,
              }}
              isClearable
            />
          </Col>
        </Row>
      </InsideHeader>

      <div className='fix-table mt-3 box-shadow-3 border-radius-5'>
        <Table
          columns={columns}
          dataSource={albumSubCategory?.data}
          pagination={false}
          loading={loading}
          onChange={handleSorting}
          rowKey='id'
        />

        <div className='text-center bg-white py-2 width-1120'>
          <ButtonCom className='width-180' onClick={() => navigate(-1)}>
            Back
          </ButtonCom>
        </div>

        {albumSubCategory?.total > 10 && (
          <div className='text-center bg-white pb-2 width-1120'>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={albumSubCategory?.total}
            />
          </div>
        )}
      </div>

      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteModel}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </>
  );
};

export default AlbumCategory;
