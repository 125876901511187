import React, { useEffect, useRef, useState } from 'react';

import { Row, Col, DatePicker, Checkbox, Input, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Spinner from '../../../components/Spinner';
import PaginationCom from '../../../components/PaginationCom';
import TableImage from '../../../components/Image/TableImage';
import { tableSortIconToggle } from '../../../utility/tableSortingForSongAndAlbum';

import {
  ExportExcel,
  ExportPdf,
  handleFileName,
} from '../../../utility/Export/Export';

import { toast } from 'react-toastify';

import { CSVLink } from 'react-csv';

import { REPORTS } from '../../../service';

const { RangePicker } = DatePicker;

const AdvertiseTab = () => {
  const [advertiseList, setAdvertiseList] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);
  const [sortedInfo, setSortedInfo] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueOfRangePicker, setValueOfRangePicker] = useState(null);

  const [displayed, setDisplayed] = useState(null);
  const [clicked, setClicked] = useState(null);

  const [csv, setCsv] = useState([]);
  const csvLink = useRef();

  const [spinnerValue, setSpinnerValue] = useState(false);

  const headers = [
    { label: 'Title', key: 'title' },
    { label: 'Android', key: 'android' },
    { label: 'iOS', key: 'ios' },
    { label: 'Active', key: 'active' },
    { label: 'Displayed', key: 'displayed' },
    { label: 'Clicked', key: 'clicked' },
  ];

  const getAdvertiseReport = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    startDate = null,
    endDate = null
  ) => {
    setLoading(true);
    try {
      const res = await REPORTS.REPORTS_ADVERTISE(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        startDate,
        endDate
      );
      if (res.status === 1 || res.status === '1') {
        setAdvertiseList(res);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSorting = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
      setDisplayed(null);
      setClicked(null);
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
      if (sorter?.field === 'displayed') {
        setDisplayed(true);
      }
      if (sorter?.field === 'clicked') {
        setClicked(true);
      }
    } else {
      setSort({ createdAt: -1 });
      if (sorter?.field === 'displayed') {
        setDisplayed(false);
      }
      if (sorter?.field === 'clicked') {
        setClicked(false);
      }
    }
  };

  const handleChangeOfSearch = (e) => {
    if (!e?.target?.value) {
      setSearch(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const enterSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const handleChangeOfMostViewed = (e) => {
    setCurrentPage(1);
    setDisplayed(e.target.checked);
    setClicked(null);
    if (e.target.checked) {
      setSort({
        displayed: -1,
      });
      setSortedInfo({
        order: 'descend',
        columnKey: 'displayed',
      });
    } else {
      setSort({ createdAt: -1 });
      setSortedInfo({});
    }
  };

  const handleChangeOfMostClick = (e) => {
    setCurrentPage(1);
    setClicked(e.target.checked);
    setDisplayed(null);
    if (e.target.checked) {
      setSort({
        clicked: -1,
      });
      setSortedInfo({
        order: 'descend',
        columnKey: 'clicked',
      });
    } else {
      setSort({ createdAt: -1 });
      setSortedInfo({});
    }
  };

  const handleChangeOfRangePicker = (e) => {
    setCurrentPage(1);
    setValueOfRangePicker(e);
    if (e) {
      setStartDate(
        new Date(e[0]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
      setEndDate(
        new Date(e[1]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const clearAllFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSearch(null);
    setTempSearch(null);
    setDisplayed(null);
    setClicked(null);
    setCurrentPage(1);
    setPageSize(10);
    setSortedInfo({});
    setSort(null);
    setSearch(null);
    setValueOfRangePicker();
  };

  const exportData = async () => {
    setSpinnerValue(true);
    try {
      const res = await REPORTS.REPORTS_ADVERTISE(
        '-1',
        0,
        search,
        sort,
        startDate,
        endDate
      );
      if (res.status === 1 || res.status === '1') {
        return res;
      }
    } catch (error) {
      setSpinnerValue(false);
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setSpinnerValue(false);
    }
  };

  const generatePDF = async () => {
    let res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const pdftableColumn = [
        'Title',
        'Android',
        'iOS',
        'Active',
        'Displayed',
        'Clicked',
      ];
      const pdftableRows = [];
      res?.data.forEach((item) => {
        pdftableRows.push([
          item.title,
          item.platformAndroid,
          item.platformIPhone,
          item.isActivate,
          item.displayed,
          item.clicked,
        ]);
      });
      ExportPdf(pdftableColumn, pdftableRows, 'advertisementReport');
    }
  };

  const generateExcel = async () => {
    const res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data.map((item) => {
        return {
          Title: item.title,
          Android: item.platformAndroid,
          iOS: item.platformIPhone,
          Active: item.isActivate,
          Displayed: item.displayed,
          Clicked: item.clicked,
        };
      });
      ExportExcel(temp, 'advertisementReport');
    }
  };

  const handleDownloadCsv = async () => {
    const res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data.map((item) => {
        return {
          title: item.title,
          android: item.platformAndroid,
          ios: item.platformIPhone,
          active: item.isActivate,
          displayed: item.displayed,
          clicked: item.clicked,
        };
      });
      setCsv([...temp]);
      CSVLink.current.link.click();
    }
  };

  useEffect(() => {
    getAdvertiseReport(currentPage, pageSize, search, sort, startDate, endDate);
  }, [currentPage, endDate, pageSize, search, sort, startDate]);

  const columns = [
    {
      title: 'Cover Image',
      dataIndex: 'media_item',
      key: 'media_item',
      align: 'left',
      render: (media_item) => <TableImage image={media_item?.image} />,
      width: 130,
    },
    {
      title: (column) => tableSortIconToggle('Ad Title', 'title', column),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: advertiseList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'title' ? sortedInfo.order : undefined,
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: 'Android',
      dataIndex: 'platformAndroid',
      key: 'platformAndroid',
      align: 'center',
      render: (platformAndroid) => {
        return <Checkbox disabled checked={platformAndroid} />;
      },
      width: 120,
    },
    {
      title: 'iOS',
      dataIndex: 'platformIPhone',
      key: 'platformIPhone',
      align: 'center',
      render: (platformIPhone) => {
        return <Checkbox disabled checked={platformIPhone} />;
      },
      width: 100,
    },
    {
      title: 'Active',
      dataIndex: 'isActivate',
      key: 'isActivate',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      render: (_, record) => {
        return <Checkbox disabled checked={record} />;
      },
      width: 100,
    },
    {
      title: (column) => tableSortIconToggle('Displayed', 'displayed', column),
      key: 'displayed',
      dataIndex: 'displayed',
      align: 'center',
      sorter: advertiseList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'displayed' ? sortedInfo.order : undefined,
      sortDirections: ['ascend', 'descend'],
      width: 150,
    },
    {
      title: (column) => tableSortIconToggle('Clicked', 'clicked', column),
      key: 'clicked',
      dataIndex: 'clicked',
      align: 'center',
      sorter: advertiseList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'clicked' ? sortedInfo.order : undefined,
      sortDirections: ['ascend', 'descend'],
      width: 150,
    },
  ];

  return (
    <>
      {/* Inside Header */}
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <div className='bg-white box-shadow-3 border-radius-5 px-3 pt-3 pb-1'>
            <Row gutter={20} className='align-items-center'>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <Input
                  placeholder='Start Typing to find Advertisement'
                  className='input-control'
                  onChange={(e) => handleChangeOfSearch(e)}
                  onKeyPress={(e) => {
                    if (e?.key === 'Enter' && e.target.value) {
                      enterSearch();
                    }
                  }}
                  suffix={
                    <SearchOutlined
                      style={{ color: '#8f9bb3' }}
                      onClick={enterSearch}
                    />
                  }
                  value={tempSearch}
                  allowClear
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <div className='d-flex align-items-center'>
                  <h3 className='font-size-16 mb-0 mr-1'>Most Viewed Ads</h3>
                  <Checkbox
                    className='h-25px'
                    checked={displayed}
                    onChange={(e) => handleChangeOfMostViewed(e)}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <div className='d-flex align-items-center'>
                  <h3 className='font-size-16 mb-0 mr-1'>Most Clicked Ads</h3>
                  <Checkbox
                    className='h-25px'
                    checked={clicked}
                    onChange={(e) => handleChangeOfMostClick(e)}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <RangePicker
                  className='input-control w-100'
                  onChange={(e) => handleChangeOfRangePicker(e)}
                  value={valueOfRangePicker}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={16}
                xl={16}
                className='gutter-row mb-1'
              >
                <div className='d-flex flex-wrap align-items-center'>
                  <div className='mb-1 mr-2 mt-1 bg-white font-weight-600'>
                    Export
                  </div>
                  <button
                    className='bg-light-gray font-weight-600 border-0 border-radius-3 mb-1 py-8px px-2 mr-1 cursor-pointer'
                    onClick={handleDownloadCsv}
                    disabled={spinnerValue}
                  >
                    CSV
                  </button>
                  <CSVLink
                    data={csv}
                    headers={headers}
                    filename={handleFileName('advertisementReport', 'csv')}
                    ref={csvLink}
                    className='text-black font-weight-600'
                  ></CSVLink>
                  <button
                    className='bg-light-gray font-weight-600 border-0 border-radius-3 mb-1 py-8px px-2 mr-1 cursor-pointer'
                    onClick={generateExcel}
                    disabled={spinnerValue}
                  >
                    Excel
                  </button>
                  <button
                    className='bg-light-gray font-weight-600 border-0 border-radius-3 mb-1 py-8px px-2 mr-1 cursor-pointer'
                    onClick={() => generatePDF()}
                    disabled={spinnerValue}
                  >
                    Pdf
                  </button>
                  <h3 className='clear-all mt-1' onClick={clearAllFilter}>
                    Clear All
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* Tables Data */}
      <div className='fix-table mt-3 box-shadow-3 border-radius-5'>
        <Table
          columns={columns}
          dataSource={advertiseList?.data}
          pagination={false}
          loading={loading}
          onChange={handleSorting}
          rowKey='id'
        />

        {/* Pagination */}
        {advertiseList?.total > 10 && (
          <div className='text-center bg-white py-2 width-1120 '>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={advertiseList?.total}
            />
          </div>
        )}
      </div>

      {/* Spinner*/}
      {spinnerValue && <Spinner />}
    </>
  );
};

export default AdvertiseTab;
