import { useEffect, useState } from 'react';

import InsideHeader from '../../../components/InsideHeader/InsideHeader';
import PaginationCom from '../../../components/PaginationCom';
import DeleteModel from '../../../components/DeleteModel';
import TableImage from '../../../components/Image/TableImage';
import ButtonCom from '../../../components/Button/ButtonCom';

import { Table, Input, Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

import { CATEGORY } from '../../../service';

import { tableSortIconToggle } from '../../../utility/tableSorting';

const Category = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState(null);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();

  const fetchCategory = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null
  ) => {
    setLoading(true);
    try {
      const res = await CATEGORY.GET_CATEGORY(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort
      );
      if (res.status === 1 || res.status === '1') {
        setCategory(res);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const deleteCategory = async () => {
    try {
      const res = await CATEGORY.DELETE_CATEGORY(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (category?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchCategory(page, pageSize, search, sort);
        }
        toast.success(res?.message);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const searchCategory = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e?.target?.value);
    if (!e?.target?.value) {
      setSearch(null);
      setTempSearch(null);
    }
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchCategory(currentPage, pageSize, search, sort);
  }, [currentPage, pageSize, search, sort]);

  const columns = [
    {
      title: 'Cover Image',
      dataIndex: 'media_item',
      key: 'media_item',
      render: (media_item) => <TableImage image={media_item?.image} />,
      width: 130,
    },
    {
      title: (column) =>
        tableSortIconToggle('Category Name', 'categoryName', column),
      dataIndex: 'categoryName',
      key: 'categoryName',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: category?.data?.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggle('Subtitle', 'subtitle', column),
      dataIndex: 'subtitle',
      key: 'subtitle',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: category?.data?.length > 1 ? true : false,
      ellipsis: true,
      render: (subtitle) => (subtitle ? subtitle : '-'),
    },
    {
      title: 'Sub-Category Count',
      dataIndex: 'count',
      key: 'count',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      width: 190,
    },
    {
      title: (column) =>
        tableSortIconToggle('Position No', 'positionNo', column),
      dataIndex: 'positionNo',
      key: 'positionNo',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: category?.data?.length > 1 ? true : false,
      width: 140,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 150,
      render: (_, record) => {
        return (
          <div className='albumAction'>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Category'>
                <EditOutlined
                  onClick={() => navigate(`/category/edit/${record?.id}`)}
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Category'>
                <DeleteOutlined
                  onClick={() => {
                    setDeleteId(record?.id);
                    setIsDeleteModalVisible(true);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  if (permissions?.isUpdate === false && permissions?.isDelete === false) {
    delete columns[5];
  }

  return (
    <>
      {/*Search section*/}
      <InsideHeader className='search-section'>
        <Input
          placeholder='Start Typing Search Category'
          onChange={(e) => handleChangeOfSearch(e)}
          onKeyPress={(e) => {
            if (e?.key === 'Enter' && e.target.value) {
              searchCategory();
            }
          }}
          suffix={
            <Tooltip title='Search Now'>
              <SearchOutlined
                onClick={searchCategory}
                style={{ color: '#8f9bb3' }}
              />
            </Tooltip>
          }
          className='input-control'
          allowClear
        />
        <div className='search-section__btn-group'>
          {(permissions?.isCreate || permissions?.isCreate === undefined) && (
            <ButtonCom
              type='primary'
              className=' mr-1'
              onClick={() => navigate('/category/add')}
            >
              Create New Category
            </ButtonCom>
          )}
          <ButtonCom
            type='primary'
            className=' mr-1 width-180'
            onClick={() => navigate('/category/album')}
          >
            Album Category
          </ButtonCom>
          <ButtonCom
            type='primary'
            className='width-180'
            onClick={() => navigate('/category/sub-category')}
          >
            Sub-Category
          </ButtonCom>
        </div>
      </InsideHeader>

      <div className='fix-table mt-3 box-shadow-3 border-radius-5'>
        <Table
          columns={columns}
          dataSource={category?.data}
          rowKey='id'
          pagination={false}
          loading={loading}
          onChange={onChange}
        />

        {category?.total > 10 && (
          <div className='text-center bg-white p-3 width-1120'>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={category?.total}
            />
          </div>
        )}
      </div>

      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteCategory}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </>
  );
};

export default Category;
