import { useEffect, useState } from 'react';

import { Table, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import DeleteModel from '../../components/DeleteModel';
import ButtonCom from '../../components/Button/ButtonCom';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { ADMIN_ROLES } from '../../service';

import { tableSortIconToggle } from '../../utility/tableSorting';

const AdminRoles = ({ permissions }) => {
  const [adminUsersRole, setAdminUsersRole] = useState({});
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const navigate = useNavigate();

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const res = await ADMIN_ROLES.GET();
      if (res.status === 1 || res.status === '1') {
        setAdminUsersRole(res);
      } else {
        throw res;
      }
    } catch (err) {
      toast.error(err.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const deleteRole = async () => {
    try {
      const res = await ADMIN_ROLES.DELETE(recordId);
      if (res.status === 1 || res.status === '1') {
        setRecordId(null);
        setIsDeleteModalVisible(false);
        toast.success(res.message);
        fetchRoles();
      }
    } catch (err) {
      toast.error(err.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const columns = [
    {
      title: (column) => tableSortIconToggle('Role', 'name', column),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      align: 'center',
    },
    {
      title: (column) => tableSortIconToggle('Users', 'users_count', column),
      dataIndex: 'users_count',
      key: 'users_count',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a?.users_count - b?.users_count,
      width: 160,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 160,
      render: (item, record) => {
        return (
          <>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Roles'>
                <EditOutlined
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                  onClick={() =>
                    navigate(`/admin-users/roles/edit/${record?.id}`)
                  }
                />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Roles'>
                <DeleteOutlined
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setRecordId(record.id);
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  if (permissions?.isUpdate === false && permissions?.isDelete === false) {
    delete columns[3];
  }

  return (
    <>
      {(permissions?.isCreate || permissions?.isCreate === undefined) && (
        <InsideHeader>
          <ButtonCom
            type='primary'
            onClick={() => navigate('/admin-users/roles/add')}
          >
            Create New Role
          </ButtonCom>
        </InsideHeader>
      )}

      <div
        className='fix-table mt-3 border-radius-5 box-shadow-3'
        style={{ width: '90%' }}
      >
        <Table
          columns={columns}
          dataSource={adminUsersRole?.data}
          pagination={false}
          rowKey='id'
          loading={loading}
        />

        <div className='text-center bg-white py-2 width-1120'>
          <ButtonCom
            className='width-180'
            onClick={() => navigate('/admin-users')}
          >
            Back
          </ButtonCom>
        </div>
      </div>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteRole}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </>
  );
};

export default AdminRoles;
