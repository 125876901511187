import React from 'react';

import { Col, Drawer, Row } from 'antd';

const InfoDrawer = ({
  setInfoSliderVisible,
  infoSliderVisible,
  infoSliderContent,
}) => {
  return (
    <>
      <Drawer
        title='Advertise Details'
        placement='right'
        onClose={() => setInfoSliderVisible(false)}
        visible={infoSliderVisible}
      >
        <div className='text-center'>
          {infoSliderContent?.media_item?.image ? (
            <img
              src={infoSliderContent.media_item.image}
              alt=''
              width='80px'
              height='80px'
            />
          ) : (
            <img width='80px' height='80px' alt='' />
          )}
        </div>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>
              Advertise Title
            </h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'> {infoSliderContent?.title}</h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>
              Advertise Type
            </h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'> {infoSliderContent?.type}</h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Ad Count</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>{infoSliderContent?.adCount}</h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Clicked</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>{infoSliderContent?.clicked}</h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Displayed</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.displayed}
            </h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Redirect Url</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>{infoSliderContent?.url}</h2>
          </Col>
        </Row>

        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Android</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.platformAndroid ? 'Yes' : 'No'}
            </h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>iOS</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.platformIPhone ? 'Yes' : 'No'}
            </h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'> Active</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.isActivate ? 'Yes' : 'No'}
            </h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Start Date</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.startDate
                ? new Date(infoSliderContent?.startDate).toLocaleString(
                    'en-IN',
                    {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }
                  )
                : '-'}
            </h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>End Date</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.startDate
                ? new Date(infoSliderContent?.endDate).toLocaleString('en-IN', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                : '-'}
            </h2>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default InfoDrawer;
