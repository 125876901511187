import React, { useEffect, useState } from 'react';

import { Row, Col } from 'antd';

import { toast } from 'react-toastify';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import ThreeBox from '../../components/Dashboard/ThreeBox';
import Favorites from '../../components/Dashboard/Favorites';
import TrendingMonthItem from '../../components/Dashboard/TrendingMonthItem';
import TotalMedia from '../../components/Dashboard/TotalMedia';
import TrendingSearch from '../../components/Dashboard/TrendingSearch';
import TotalUser from '../../components/Dashboard/TotalUser';
import UserDownload from '../../components/Dashboard/UserDownload';
import SelectDropDown from '../../components/Select';

import { DASHBOARD } from '../../service';

const Dashboard = () => {
  const [dashboard, setDashboard] = useState({});
  const [media, setMedia] = useState([]);
  const [totalMedia, setTotalMedia] = useState(0);
  const [filter, setFilter] = useState(null);

  const optionsForMedia = {
    pieHole: 0.55,
    is3D: false,
    legend: { position: 'bottom' },
    pieSliceText: 'value',
    slices: {
      0: { color: '#7D3AC0' },
      1: { color: '#B04BCF' },
      2: { color: '#DB4CB2' },
      3: { color: '#EA548B' },
      4: { color: '#EA7369' },
    },
  };

  const optionsForTotalUser = {
    pieHole: 0.55,
    is3D: false,
    legend: { position: 'bottom' },
    pieSliceText: 'value',
    slices: {
      0: { color: '#7D3AC0' },
      1: { color: '#B04BCF' },
    },
  };

  const optionForUserDownload = {
    legend: { position: 'right' },
    series: {
      0: { color: '#7D3AC0' },
      1: { color: '#B04BCF' },
    },
  };

  const dropdownOption = [
    {
      label: 'Life Time',
      value: null,
    },
    {
      label: 'Today',
      value: 'today',
    },
    {
      label: 'Yesterday',
      value: 'yesterday',
    },
    {
      label: 'This Week',
      value: 'this_week',
    },
    {
      label: 'Previous Week',
      value: 'previous_week',
    },
    {
      label: 'This Month',
      value: 'this_month',
    },
    {
      label: 'Previous month',
      value: 'previous_month',
    },
    {
      label: 'This Year',
      value: 'this_year',
    },
    {
      label: 'Previous Year',
      value: 'previous_year',
    },
  ];

  const fetch = async (search = null) => {
    try {
      const res = await DASHBOARD.GET_DASHBOARD(search);
      if (res?.status === 1 || res?.status === '1') {
        setDashboard(res?.data);

        let tempTotalMedia = 0;
        const map = res?.data?.media?.map((item) => {
          const tempArr = [];
          if (item?.album_total) {
            tempArr.push(['Album', +item?.album_total]);
            tempTotalMedia += +item?.album_total;
          }
          if (item?.artist_total) {
            tempArr.push(['Artist', +item?.artist_total]);
            tempTotalMedia += +item?.artist_total;
          }
          if (item?.podcast_total) {
            tempArr.push(['Podcast', +item?.podcast_total]);
            tempTotalMedia += +item?.podcast_total;
          }
          if (item?.song_total) {
            tempArr.push(['Song', +item?.song_total]);
            tempTotalMedia += +item?.song_total;
          }
          if (item?.video_total) {
            tempArr.push(['Video', +item?.video_total]);
            tempTotalMedia += +item?.video_total;
          }
          return tempArr;
        })[0];
        const tempMedia = [['Media', 'Category'], ...map];
        setMedia(tempMedia);
        setTotalMedia(tempTotalMedia);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const handleChangeOfFilter = (e) => {
    setDashboard([]);
    setMedia([]);
    setTotalMedia(0);
    setFilter(e);
  };

  useEffect(() => {
    fetch(filter?.value);
  }, [filter?.value]);

  const data = [
    ['Months', 'Android', 'IOS'],
    ['Jan', 1, 1],
    ['Feb', 10, 20],
    ['Mar', 30, 40],
    ['Apr', 40, 50],
    ['May', 50, 60],
    ['Jun', 60, 70],
    ['Jul', 70, 80],
    ['Aug', 80, 90],
    ['Sep', 100, 120],
    ['Oct', 140, 90],
    ['Nov', 100, 140],
    ['Dec', 200, 150],
  ];

  const totalUser = [
    ['Android', 'IOS'],
    ['Android', 50],
    ['IOS', 40],
  ];

  return (
    <>
      {/* Header */}
      <InsideHeader>
        <Row>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <SelectDropDown
              options={dropdownOption}
              onChange={handleChangeOfFilter}
              value={
                filter || {
                  label: 'Life Time',
                  value: null,
                }
              }
            />
          </Col>
        </Row>
      </InsideHeader>

      {/* Total media & Trending Searches  */}
      <Row justify='space-between' gutter={25}>
        <TotalMedia
          totalMedia={totalMedia}
          media={media}
          optionsForMedia={optionsForMedia}
        />
        <TrendingSearch trendingSearch={dashboard?.trending_search} />
      </Row>

      {/* Total Users & Users Download  */}
      <Row justify='space-between' gutter={25}>
        <TotalUser
          totalUser={totalUser}
          optionsForTotalUser={optionsForTotalUser}
        />
        <UserDownload
          data={data}
          optionForUserDownload={optionForUserDownload}
        />
      </Row>

      {/* This Month */}
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className='bg-white mt-3 p-2 box-shadow-3 border-radius-10'
        >
          <h2 className='font-weight-700 mb-0'>This Month</h2>
          <Row justify='space-between' gutter={25}>
            <TrendingMonthItem
              name='Trending Tracks'
              data={dashboard?.trendings?.songs}
            />
            <TrendingMonthItem
              name='Trending Albums'
              data={dashboard?.trendings?.albums}
            />
            <TrendingMonthItem
              name='Trending Songs'
              data={dashboard?.trendings?.artists}
            />
          </Row>
        </Col>
      </Row>

      {/* Ads Shown & Top 10 Favorite Tracks */}
      <Row justify='space-between' gutter={25}>
        <ThreeBox
          title='Ads Shown'
          boxOneTitle='Total Ads'
          boxOneNum='3.4k'
          boxOnePer='23.5%'
          boxTwoTitle='Mobile'
          boxTwoNum='3.4k'
          boxTwoPer='23.5%'
          boxThreeTitle='Per Users'
          boxThreeNum='3.4k'
          boxThreePer='23.5%'
        />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
          className=' mt-3 gutter-row'
        >
          <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
            <Favorites
              title='Top 10 Favorite Tracks'
              nameTitle='Name'
              countTitle='Favorite Count'
              progress={dashboard?.most_favorites?.songs}
            />
          </div>
        </Col>
      </Row>

      {/* Ads Clicks & Top 10 Favorite Albums  */}
      <Row justify='space-between' gutter={25}>
        <ThreeBox
          title='Ads Clicks'
          boxOneTitle='Total Clicks'
          boxOneNum='3.4k'
          boxOnePer='23.5%'
          boxTwoTitle='Mobile'
          boxTwoNum='3.4k'
          boxTwoPer='23.5%'
          boxThreeTitle='Web'
          boxThreeNum='3.4k'
          boxThreePer='23.5%'
        />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
          className=' mt-3 gutter-row'
        >
          <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
            <Favorites
              title='Top 10 Favorite Albums'
              nameTitle='Name'
              countTitle='Favorite Count'
              progress={dashboard?.most_favorites?.albums}
            />
          </div>
        </Col>
      </Row>

      {/* Top Ads & Top 10 Favorite Artist */}
      <Row justify='space-between' gutter={25}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={10}
          xl={10}
          className=' mt-3 gutter-row'
        >
          <Row justify='space-between' gutter={25}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
              <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
                <h2 className='font-weight-700 mb-0'>Top Ads</h2>
                <Row justify='space-between' gutter={25}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className=' mt-2 gutter-row'
                  >
                    <div className='bg-light-gray2 border-radius-10 p-3 '></div>
                  </Col>
                </Row>
                <Row justify='space-between' gutter={25}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className=' mt-2 gutter-row'
                  >
                    <div className='bg-light-gray2 border-radius-10 p-3 '></div>
                  </Col>
                </Row>
                <Row justify='space-between' gutter={25}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className=' mt-2 gutter-row'
                  >
                    <div className='bg-light-gray2 border-radius-10 p-3 '></div>
                  </Col>
                </Row>
                <Row justify='space-between' gutter={25}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className=' mt-2 gutter-row'
                  >
                    <div className='bg-light-gray2 border-radius-10 p-3 '></div>
                  </Col>
                </Row>
                <Row justify='space-between' gutter={25}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className=' mt-2 gutter-row'
                  >
                    <div className='bg-light-gray2 border-radius-10 p-3 '></div>
                  </Col>
                </Row>
              </div>
            </Col>
            {/* User Playlists */}
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='mt-3 gutter-row'
            >
              <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
                <h2 className='font-weight-700'>User Playlists</h2>
                <div className='bg-light-gray2 border-radius-10 p-2 '>
                  <h3 className='font-weight-700 mb-0'>
                    Total Playlist Created
                  </h3>
                  <Row align='bottom'>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className='gutter-row'
                    >
                      <h1 className='pl-1 font-size-50 mb-0'>5.3K</h1>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className=' mt-2 gutter-row'
                    >
                      <div className='d-flex align-items-center pl-3'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='20'
                          viewBox='0 0 24 24'
                          className='eva eva-arrow-upward-outline'
                          fill='currentColor'
                        >
                          <g data-name='Layer 2'>
                            <g data-name='arrow-upward'>
                              <rect
                                width='24'
                                height='24'
                                transform='rotate(180 12 12)'
                                opacity='0'
                              ></rect>
                              <path d='M5.23 10.64a1 1 0 0 0 1.41.13L11 7.14V19a1 1 0 0 0 2 0V7.14l4.36 3.63a1 1 0 1 0 1.28-1.54l-6-5-.15-.09-.13-.07a1 1 0 0 0-.72 0l-.13.07-.15.09-6 5a1 1 0 0 0-.13 1.41z'></path>
                            </g>
                          </g>
                        </svg>
                        <h3 className='pl-1 mb-0'> 23.5%</h3>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
          className=' mt-3 gutter-row'
        >
          <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
            <Favorites
              title='Top 10 Favorite Artist'
              nameTitle='Name'
              countTitle='Favorite Count'
              progress={dashboard?.most_favorites?.artists}
            />
          </div>
        </Col>
      </Row>

      {/* Total Time Consumed & Monthly Active Users */}
      <Row justify='space-between' gutter={25}>
        <ThreeBox
          title='Total Time Consumed'
          boxOneTitle='Jalso Wide Total Consumed'
          boxOneNum='3.4k'
          boxOnePer='23.5%'
          boxTwoTitle='Per Artist'
          boxTwoNum='3.4k'
          boxTwoPer='23.5%'
          boxThreeTitle='Per Users'
          boxThreeNum='3.4k'
          boxThreePer='23.5%'
        />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
          className=' mt-3 gutter-row'
        >
          <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
            <h2 className='font-weight-700'>Monthly Active Users</h2>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
