import { useEffect, useState } from 'react';

import { Col, Row, Tooltip } from 'antd';
import { Table, Checkbox, Input, DatePicker } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  FilterFilled,
  CopyOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import PaginationCom from '../../components/PaginationCom';
import DeleteModel from '../../components/DeleteModel';
import ButtonCom from '../../components/Button/ButtonCom';
import InfoDrawer from './InfoDrawer/InfoDrawer';

import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import { toast } from 'react-toastify';

import { tableSortIconToggle } from '../../utility/tableSorting';

import { NOTIFICATION } from '../../service/index';

const { RangePicker } = DatePicker;

const Notifications = ({ permissions }) => {
  const [notifications, setNotifications] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);

  const [scheduled, setScheduled] = useState(null);
  const [published, setPublished] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isshowFilter, setIsShowFilter] = useState(false);

  const [infoSliderVisible, setInfoSliderVisible] = useState(false);
  const [infoSliderContent, setInfoSliderContent] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueOfRangePicker, setValueOfRangePicker] = useState(null);

  const navigate = useNavigate();

  const fetchNotifications = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    scheduled = null,
    published = null,
    startDate = null,
    endDate = null
  ) => {
    setTableLoading(true);
    try {
      const res = await NOTIFICATION.GET_NOTIFICATION(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        scheduled,
        published,
        startDate,
        endDate
      );
      if (res.status === 1 || res.status === '1') {
        setNotifications(res);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || 'Something went wrong!');
    } finally {
      setTableLoading(false);
    }
  };

  const deleteNotification = async () => {
    setTableLoading(true);
    try {
      const res = await NOTIFICATION.DELETE_NOTIFICATION(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (notifications?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchNotifications(
            page,
            pageSize,
            searchValue,
            sort,
            scheduled,
            published
          );
        }
      }
      toast.success(res?.message);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    } finally {
      setTableLoading(false);
    }
  };

  const copyNotification = (record) => {
    removeFromLocalStorage();
    navigate(`/notifications/clone/${record?.id}`);
  };

  const editNotification = (record) => {
    removeFromLocalStorage();
    navigate(`/notifications/edit/${record?.id}`);
  };

  const addNotification = () => {
    removeFromLocalStorage();
    navigate('/notifications/add');
  };

  const removeFromLocalStorage = () => {
    localStorage.removeItem('notification-step-1');
    localStorage.removeItem('notification-step-2');
    localStorage.removeItem('notification-step-3');
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const handleChangeOfScheduled = (e) => {
    setCurrentPage(1);
    setScheduled(e?.target?.checked);
    setPublished(false);
  };

  const handleChangeOfPublished = (e) => {
    setCurrentPage(1);
    setPublished(e?.target?.checked);
    setScheduled(false);
  };

  const clearAllFilter = () => {
    setPageSize(10);
    setCurrentPage(1);
    setSearchValue(null);
    setTempSearch(null);
    setScheduled(false);
    setPublished(false);
    setStartDate(null);
    setEndDate(null);
    setValueOfRangePicker(null);
  };

  const handleChangeOfRangePicker = (e) => {
    setValueOfRangePicker(e);
    if (e) {
      setStartDate(
        new Date(e[0]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
      setEndDate(
        new Date(e[1]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleChangeOfSearch = (e) => {
    if (!e?.target?.value) {
      setSearchValue(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const onEnterSearch = (e) => {
    if (e?.key === 'Enter' && e.target.value) {
      setCurrentPage(1);
      setSearchValue(tempSearch);
    }
  };

  useEffect(() => {
    fetchNotifications(
      currentPage,
      pageSize,
      searchValue,
      sort,
      scheduled,
      published,
      startDate,
      endDate
    );
  }, [
    currentPage,
    endDate,
    pageSize,
    published,
    scheduled,
    searchValue,
    sort,
    startDate,
  ]);

  const columns = [
    {
      title: (column) =>
        tableSortIconToggle('Notification Title', 'title', column),
      dataIndex: 'title',
      key: 'title',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggle('Date', 'publish_date', column),
      dataIndex: 'publish_date',
      key: 'publish_date',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      render: (_, record) => {
        const date = record.publish_date;
        const formattedDate = moment(date).format('DD-MM-YYYY  h:mm:ss a');
        return formattedDate;
      },
      width: 250,
    },
    {
      title: (column) =>
        tableSortIconToggle('Android', 'platformAndroid', column),
      dataIndex: 'platformAndroid',
      key: 'platformAndroid',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      render: (_, record) => (
        <Checkbox
          disabled
          checked={record && record.platformAndroid ? true : false}
        />
      ),
      width: 150,
    },
    {
      title: (column) => tableSortIconToggle('iOS', 'platformIPhone', column),
      dataIndex: 'platformIPhone',
      key: 'platformIPhone',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      render: (_, record) => (
        <Checkbox
          disabled
          checked={record && record.platformIPhone ? true : false}
        />
      ),
      width: 150,
    },
    {
      title: (column) => tableSortIconToggle('Status', 'isPublished', column),
      key: 'isPublished',
      dataIndex: 'isPublished',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      render: (_, record) => (
        <p className='table-tag'>
          {record?.isPublished ? 'Published' : 'Scheduled'}
        </p>
      ),
      width: 150,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      render: (_, record) => {
        return (
          <>
            <div className='albumAction'>
              {(permissions?.isCreate ||
                permissions?.isCreate === undefined) && (
                <Tooltip title='Duplicate Notification'>
                  <CopyOutlined
                    onClick={() => copyNotification(record)}
                    className='font-size-18 clone-btn-hover cursor-pointer mx-1'
                  />
                </Tooltip>
              )}
              <Tooltip title='View Notification'>
                <InfoCircleOutlined
                  onClick={() => {
                    setInfoSliderContent(record);
                    setInfoSliderVisible(true);
                  }}
                  className='font-size-20 info-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
              {record?.isPublished === false && (
                <>
                  {(permissions?.isUpdate ||
                    permissions?.isUpdate === undefined) && (
                    <Tooltip title='Edit Notification'>
                      <EditOutlined
                        onClick={() => editNotification(record)}
                        className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                      />
                    </Tooltip>
                  )}
                  {(permissions?.isDelete ||
                    permissions?.isDelete === undefined) && (
                    <Tooltip title='Delete Notification'>
                      <DeleteOutlined
                        onClick={() => {
                          setIsDeleteModalVisible(true);
                          setDeleteId(record?.id);
                        }}
                        className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          </>
        );
      },
      width: 200,
    },
  ];

  return (
    <>
      {/*Search sections*/}
      <InsideHeader className='search-section'>
        <Input
          placeholder='Start Typing Search Notifications'
          suffix={
            <SearchOutlined
              onClick={() => setSearchValue(tempSearch)}
              style={{ color: '#8f9bb3' }}
            />
          }
          className='input-control'
          onChange={(e) => handleChangeOfSearch(e)}
          onKeyPress={(e) => onEnterSearch(e)}
          allowClear
          value={tempSearch}
        />

        <div className='search-section__btn-group'>
          {(permissions?.isCreate || permissions?.isCreate === undefined) && (
            <ButtonCom
              type='primary'
              className='mr-1'
              onClick={addNotification}
            >
              Create New Notifications
            </ButtonCom>
          )}
          <ButtonCom
            type='primary'
            onClick={() => setIsShowFilter(!isshowFilter)}
          >
            <div className='d-flex align-items-center'>
              Notifications Filters
              <FilterFilled className='ml-1' />
            </div>
          </ButtonCom>
        </div>
      </InsideHeader>

      {/* Filter */}
      {isshowFilter && (
        <>
          <InsideHeader className='mt-3 pb-1'>
            <Row gutter={20} className='align-items-center'>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <RangePicker
                  onChange={(e) => handleChangeOfRangePicker(e)}
                  value={valueOfRangePicker}
                />
              </Col>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className='gutter-row mb-1'
              >
                <div className='d-flex align-items-center'>
                  <h3 className='font-size-16 mb-0 mr-1'>Scheduled</h3>
                  <Checkbox
                    className='h-25px'
                    checked={scheduled}
                    onChange={(e) => handleChangeOfScheduled(e)}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className='gutter-row mb-1'
              >
                <div className='d-flex align-items-center'>
                  <h3 className='font-size-16 mb-0 mr-1'>Published</h3>
                  <Checkbox
                    className='h-25px'
                    checked={published}
                    onChange={(e) => handleChangeOfPublished(e)}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className='gutter-row mb-1'
              >
                <h3
                  className='text-blue text-decoration-underline font-size-16 mb-0 cursor-pointer d-inline'
                  onClick={clearAllFilter}
                >
                  Clear All
                </h3>
              </Col>
            </Row>
          </InsideHeader>
        </>
      )}

      {/* Table */}
      <div className='fix-table mt-3 border-radius-5 box-shadow-3'>
        <Table
          columns={columns}
          dataSource={notifications?.data}
          pagination={false}
          rowKey='id'
          loading={tableLoading}
          onChange={onChange}
        />

        {/* Pagination  */}
        {notifications?.total > 10 && (
          <div className='text-center bg-white p-3 width-1120'>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={notifications?.total}
            />
          </div>
        )}
      </div>

      {/* Delete model */}
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteNotification}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />

      {/* Info slider */}
      <InfoDrawer
        setInfoSliderVisible={setInfoSliderVisible}
        infoSliderVisible={infoSliderVisible}
        infoSliderContent={infoSliderContent}
      />
    </>
  );
};

export default Notifications;
