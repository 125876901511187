import { useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Form, Input, Upload, InputNumber } from 'antd';

import { ALBUM_CATEGORY, CATEGORY, SUB_CATEGORY } from '../../../../service';
import { BASE_URL } from '../../../../service/constant';

import { toast } from 'react-toastify';

import { useNavigate, useParams } from 'react-router-dom';

import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import SelectWithPagination from '../../../../components/SelectWithPagination';
import SelectDropDown from '../../../../components/Select';
import ButtonCom from '../../../../components/Button/ButtonCom';

const AddEditSubCategory = () => {
  const [fileList, setFileList] = useState([]);

  const [mediaItemId, setMediaItemId] = useState(null);

  const [parentSubCategory, setParentSubCategory] = useState([]);

  const navigate = useNavigate();
  const { id = null } = useParams();

  const [form] = Form.useForm();

  const operationName = id ? 'edit' : 'add';

  const loadOptionsOfCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUM_CATEGORY.GET_CATEGORY(10, skip, search);
    const option = res?.data.map((item) => {
      const subcategories = item?.subcategories.map((sub) => {
        return {
          label: sub?.subCategoryName,
          value: sub?.id,
        };
      });
      return {
        label: item?.categoryName,
        value: item?.id,
        subcategories,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleChangeOfCategory = (e) => {
    form.setFieldsValue({ parentSubCategoryId: null });
    setParentSubCategory(e?.subcategories);
  };

  const singleCategory = async (id) => {
    try {
      const res = await CATEGORY.GET_CATEGORY_BY_ID(id);
      if (res.status === 1 || res.status === '1') {
        const option = res?.data?.subcategories.map((sub) => {
          return {
            label: sub?.subCategoryName,
            value: sub?.id,
          };
        });
        return option;
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const addEditSubCategories = async (values) => {
    const payload = {
      ...values,
      mediaItemId: mediaItemId,
      positionNo: values?.positionNo || 0,
      categoryId: values?.categoryId?.value,
      parentSubCategoryId: values?.parentSubCategoryId?.value || null,
    };
    delete payload?.coverImage;

    try {
      let res;
      if (operationName === 'edit') {
        res = await SUB_CATEGORY.EDIT_SUB_CATEGORY(id, payload);
      }
      if (operationName === 'add') {
        res = await SUB_CATEGORY.ADD_SUB_CATEGORY(payload);
      }
      if (res.status === 1 || res.status === '1') {
        toast.success(res?.message);
        navigate('/category/sub-category');
      }
    } catch (err) {
      toast.error(err?.message || 'something went wrong !');
    }
  };

  useEffect(() => {
    const getEditData = async () => {
      try {
        const res = await SUB_CATEGORY.GET_SUB_CATEGORY_BY_ID(id);
        if (res.status === 1 || res.status === '1') {
          const parentSubCategory = await singleCategory(
            res?.data?.category?.id
          );
          setParentSubCategory(parentSubCategory);
          const findSubCategory = parentSubCategory.find(
            (item) => item.value === res?.data?.parentSubCategoryId
          );

          form.setFieldsValue({
            ...res?.data,
            categoryId: {
              label: res?.data?.category?.categoryName,
              value: res?.data?.category?.id,
            },
            parentSubCategoryId: findSubCategory,
          });
          if (res?.data?.media_item?.image) {
            setFileList([
              {
                uid: '-1',
                name: 'cover image',
                status: 'done',
                url: res?.data?.media_item?.image,
                thubmUrl: res?.data?.media_item?.image,
              },
            ]);
            setMediaItemId(res?.data?.media_item?.id);
          }
        }
      } catch (err) {
        toast.error(err.message || 'something wen wrong');
      }
    };
    if (id) {
      getEditData();
    }
  }, [form, id]);

  const props = {
    name: 'file',
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL}/uploads-image`,
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload() {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn('File Already Uploaded! Please delete that.');
        return false;
      }
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === 'done') {
        setMediaItemId(info.file.response.data.media.id);
      } else if (info.file.status === 'error') {
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove(info) {
      setFileList([]);
      setMediaItemId(null);
    },
  };

  return (
    <>
      <InsideHeader className='add-edit-form'>
        <h1 className='font-size-28'>
          {operationName === 'add'
            ? 'Create New Sub-Category'
            : 'Edit Sub-Category'}
        </h1>
        <Form
          form={form}
          labelWrap
          name='control-hooks'
          onFinish={addEditSubCategories}
        >
          <Form.Item
            name='categoryId'
            label='Category Name'
            rules={[{ required: true, message: 'please select category' }]}
          >
            <SelectWithPagination
              placeholder='Select Category'
              loadOptions={loadOptionsOfCategory}
              onChange={(e) => handleChangeOfCategory(e)}
              additional={{
                skip: 0,
              }}
              isClearable
            />
          </Form.Item>
          <Form.Item name='parentSubCategoryId' label='Parent Sub-Category'>
            <SelectDropDown
              placeholder='Select Parent Sub-Category'
              options={parentSubCategory}
              isClearable
            />
          </Form.Item>
          <Form.Item
            name='subCategoryName'
            label='Sub-Category Name'
            rules={[
              { required: true, message: 'please write subcategory  name' },
            ]}
          >
            <Input className='input-control' />
          </Form.Item>
          <Form.Item
            name='subtitle'
            label='Subtitle'
            rules={[{ required: true, message: 'please write subtitle' }]}
          >
            <Input className='input-control' />
          </Form.Item>
          <Form.Item
            name='positionNo'
            label='Position No'
            extra={id ? '' : 'Leave blank for auto increment.'}
          >
            <InputNumber
              min={0}
              placeholder='Position No.'
              className='input-control-number'
            />
          </Form.Item>
          <Form.Item
            valuePropName='filelist'
            name='coverImage'
            label='CoverImage'
          >
            <Upload accept='.png, .jpg, .jpeg' listType='picture' {...props}>
              <ButtonCom type='primary' icon={<UploadOutlined />}>
                Click to Upload
              </ButtonCom>
            </Upload>
          </Form.Item>
          <div className='form-btn-group'>
            <ButtonCom
              className='width-180 mr-2'
              onClick={() => navigate('/category/sub-category')}
            >
              Back
            </ButtonCom>
            <ButtonCom type='primary' className='width-180' htmlType='submit'>
              {operationName === 'add' ? 'Create' : 'Edit'}
            </ButtonCom>
          </div>
        </Form>
      </InsideHeader>
    </>
  );
};

export default AddEditSubCategory;
