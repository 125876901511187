import React from 'react';

import { Col, Drawer, Row } from 'antd';

const InfoDrawer = ({
  setInfoSliderVisible,
  infoSliderVisible,
  infoSliderContent,
}) => {
  return (
    <>
      <Drawer
        title='Sub-Category Info'
        placement='right'
        onClose={() => setInfoSliderVisible(false)}
        visible={infoSliderVisible}
      >
        <div className='text-center'>
          <img
            src={infoSliderContent?.media_item?.image || ' '}
            alt=''
            width='150px'
            height='150px'
            className='border-radius-5'
          />
        </div>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={11} xl={11} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Category Name</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={11} xl={11} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.category?.categoryName}
            </h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={11} xl={11} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>
              Parent Sub-Category
            </h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={11} xl={11} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.parentName || '-'}
            </h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={11} xl={11} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>
              Sub-Category Name
            </h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={11} xl={11} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.subCategoryName || '-'}
            </h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={11} xl={11} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Subtitle</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={11} xl={11} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.subtitle || '-'}
            </h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={11} xl={11} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Position No</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={11} xl={11} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.positionNo}
            </h2>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default InfoDrawer;
