import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Steps } from 'antd';

import NotificationType from './Steps/NotificationType';
import EnterData from './Steps/EnterData';
import Publish from './Steps/Publish';

const steps = {
  0: NotificationType,
  1: EnterData,
  2: Publish,
};

const AddEditNotification = () => {
  const [step, setStep] = useState(0);
  const CurrentStep = steps[step];
  const navigate = useNavigate();

  const onNext = () => {
    setStep(step + 1);
  };

  const onBack = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      localStorage.removeItem('notification-step-1');
      localStorage.removeItem('notification-step-2');
      localStorage.removeItem('notification-step-3');
      navigate('/notifications');
    }
  };

  return (
    <>
      <div
        style={{
          maxWidth: '90%',
          margin: '30px auto',
        }}
      >
        <Steps current={step}>
          <Steps.Step
            title='Select Notification Type'
            className='cursor-pointer'
          />
          <Steps.Step title='Enter Data' className='cursor-pointer' />
          <Steps.Step title='Publish' className='cursor-pointer' />
        </Steps>
      </div>

      <div>
        <CurrentStep onNext={onNext} onBack={onBack} />
      </div>
    </>
  );
};

export default AddEditNotification;
