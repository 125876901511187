import React, { useEffect, useState } from 'react';

import { Col, Form, Input, Row } from 'antd';

import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import SelectDropDown from '../../components/Select';
import ButtonCom from '../../components/Button/ButtonCom';

import { ADD_USER } from '../../redux/features/userSlice';

import { ADMIN_ROLES, ADMIN_USERS } from '../../service';

const Profile = () => {
  const [roles, setRoles] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem('user'));

  const fetchRoles = async () => {
    try {
      const res = await ADMIN_ROLES.GET();
      if (res.status === 1 || res.status === '1') {
        const filter = res?.data
          ?.filter((item) => item.isAdmin === true)
          .map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
        setRoles([...filter]);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    const user = JSON.parse(localStorage.getItem('user'));
    form.setFieldsValue({
      ...user,
      roleId: { label: user?.role?.name, value: user?.role?.id },
    });
  };

  const onFinish = async (value) => {
    const payload = {
      ...value,
      roleId: value.roleId?.value,
    };
    try {
      const res = await ADMIN_USERS.EDIT(userData?.id, payload);
      if (res.status === 1 || res.status === '1') {
        toast.success(res?.message);
        dispatch(
          ADD_USER({
            ...userData,
            ...res?.data,
            fullname: `${res?.data?.firstname} ${res?.data?.lastname}`,
          })
        );

        localStorage.setItem(
          'user',
          JSON.stringify({
            ...userData,
            ...res?.data,
            fullname: `${res?.data?.firstname} ${res?.data?.lastname}`,
          })
        );
        setIsEdit(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    form.setFieldsValue({
      ...user,
      roleId: { label: user?.role?.name, value: user?.role?.id },
    });
  }, [form]);

  return (
    <>
      <InsideHeader className='mb-3'>
        <div className='d-flex justify-content-end '>
          <ButtonCom
            type='primary'
            onClick={() => navigate('/change-password')}
          >
            Change Password
          </ButtonCom>
        </div>
      </InsideHeader>

      <Form
        form={form}
        name='control-hooks'
        onFinish={onFinish}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
          md: { span: 8 },
          lg: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
          md: { span: 16 },
          lg: { span: 16 },
        }}
        labelAlign='left'
        className='form-style'
      >
        <Row justify='center' gutter={0} className='mt-4'>
          <Col xs={24} sm={24} md={20} lg={13} xl={13} className='gutter-row'>
            <InsideHeader>
              <div className='d-flex justify-content-between'>
                <h1 className='font-size-28'>Profile</h1>
                {isEdit === false && (
                  <ButtonCom type='primary' onClick={() => setIsEdit(true)}>
                    Edit
                  </ButtonCom>
                )}
              </div>
              <Form.Item
                name='firstname'
                label='First Name'
                rules={[{ required: true, message: 'Please fill First Name' }]}
              >
                <Input
                  size='large'
                  className='input-control'
                  placeholder='first name'
                  allowClear
                  disabled={!isEdit}
                />
              </Form.Item>
              <Form.Item
                name='lastname'
                label='Last Name'
                rules={[{ required: true, message: 'Please fill Last Name' }]}
              >
                <Input
                  className='input-control'
                  size='large'
                  placeholder='last name'
                  allowClear
                  disabled={!isEdit}
                />
              </Form.Item>
              <Form.Item
                name='email'
                label='Email'
                rules={[{ required: true, message: 'Please fill Email Id' }]}
              >
                <Input
                  className='input-control'
                  size='large'
                  placeholder='xyz@gmail.com'
                  allowClear
                  disabled={!isEdit}
                />
              </Form.Item>
              <Form.Item
                name='roleId'
                label='Role'
                rules={[{ required: true, message: 'Please select Role' }]}
              >
                <SelectDropDown
                  placeholder='Select Roles'
                  options={roles}
                  isClearable
                  isDisabled={!userData?.role?.isAdmin || !isEdit}
                />
              </Form.Item>
              <Form.Item
                name='username'
                label='Username'
                rules={[{ required: true, message: 'Please fill Username' }]}
              >
                <Input
                  className='input-control'
                  size='large'
                  placeholder='username'
                  allowClear
                  disabled={!isEdit}
                />
              </Form.Item>
              {isEdit && (
                <div className='form-btn-group'>
                  <ButtonCom className='mr-2 width-180' onClick={handleCancel}>
                    Cancel
                  </ButtonCom>
                  <ButtonCom
                    htmlType='submit'
                    type='primary'
                    className='width-180'
                  >
                    Update
                  </ButtonCom>
                </div>
              )}
            </InsideHeader>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Profile;
