import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userValue: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('jalso-token') || null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    ADD_USER: (state, actions) => {
      state.userValue = actions.payload;
    },
    ADD_TOKEN: (state, actions) => {
      state.token = actions.payload;
    },
    LOGOUT: (state) => {
      state.userValue = null;
      state.token = null;
    },
  },
});

export const { ADD_USER, LOGOUT, ADD_TOKEN } = userSlice.actions;

export default userSlice.reducer;
