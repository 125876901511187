import React from 'react';

import { Tabs } from 'antd';

import AlbumTab from './Tab/AlbumTab';
import SongTab from './Tab/SongTab';
import UserTab from './Tab/UserTab';
import AdvertiseTab from './Tab/AdvertiseTab';
import ArtistTab from './Tab/ArtistTab';
import CategoryTab from './Tab/CategoryTab';
import SubCategoryTab from './Tab/SubCategory';
import NotificationTab from './Tab/NotificationTab';

const { TabPane } = Tabs;

const Reports = ({ permission }) => {
  //console.log('permission :>> ', permission);
  return (
    <>
      <Tabs defaultActiveKey='1' size='large'>
        <TabPane tab='Albums' key='1'>
          <AlbumTab />
        </TabPane>
        <TabPane tab='Songs' key='2'>
          <SongTab />
        </TabPane>
        <TabPane tab='User' key='3'>
          <UserTab />
        </TabPane>
        <TabPane tab='Advertisement' key='4'>
          <AdvertiseTab />
        </TabPane>
        <TabPane tab='Artist' key='5'>
          <ArtistTab />
        </TabPane>
        {/* <TabPane tab='Category' key='6'>
          <CategoryTab />
        </TabPane>
        <TabPane tab='Sub-Category' key='7'>
          <SubCategoryTab />
        </TabPane> */}
        <TabPane tab='Notification' key='8'>
          <NotificationTab />
        </TabPane>
      </Tabs>
    </>
  );
};

export default Reports;
