import React from 'react';

import { Col, Drawer, Row } from 'antd';

const InfoDrawer = ({ setVisible, visible, infoData }) => {
  return (
    <>
      <Drawer
        title='Sub-Section Info'
        placement='right'
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
      >
        <div className='text-center'>
          {infoData?.media_item ? (
            <img
              src={infoData?.media_item?.image}
              alt='img'
              width='150px'
              height='150px'
              className='border-radius-5'
            />
          ) : (
            <img
              alt=''
              width='150px'
              height='150px'
              className='border-radius-5'
            />
          )}
        </div>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Item Name</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'> {infoData?.title}</h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Subtitle</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'> {infoData?.subtitle}</h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Position No.</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'> {infoData?.positionNo}</h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Item Type</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'> {infoData?.itemType}</h2>
          </Col>
        </Row>
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Show Home</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoData?.isShowHome ? 'Yes' : 'No'}
            </h2>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default InfoDrawer;
