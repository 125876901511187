import { useEffect, useState } from 'react';

import { Row, Col, Table, Checkbox, Tooltip } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { arrayMoveImmutable } from 'array-move';

import { SONGS } from '../../../service';

import ButtonCom from '../../../components/Button/ButtonCom';

const ReorderAlbumSong = () => {
  const [loading, setLoading] = useState(false);
  const [songs, setSongs] = useState([]);
  const [albumName, setAlbumName] = useState(null);

  const [changeOrder, setChangeOrder] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  const fetchSong = async (id = null) => {
    setLoading(true);
    try {
      const res = await SONGS.GET_ALBUM_SONG_ON_ALBUM_ID(id);
      if (res.status === 1 || res.status === '1') {
        setAlbumName(res.data[0]?.album?.albumName);
        const resetData = [];
        res.data.forEach((item, i) => {
          if (item.song) {
            resetData.push({
              ...item.song,
              albumSongId: item.id,
              positionNo: item.positionNo,
              index: i,
            });
          }
        });
        setSongs([...resetData]);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const handleReorder = async () => {
    const payload = songs.map((item, i) => {
      return { id: item.albumSongId, positionNo: i + 1 };
    });
    try {
      const res = await SONGS.REMOVE_ALBUM_SONG(payload);
      if (res.status === 1 || res.status === '1') {
        setChangeOrder(false);
        fetchSong(id);
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchSong(id);
  }, [id]);

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 65,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Song Title',
      dataIndex: 'songTitle',
      key: 'songTitle',
      width: 130,
      ellipsis: true,
    },
    {
      title: 'CMS Path',
      dataIndex: 'cmsPath',
      key: 'cmsPath',
      align: 'center',
      width: 110,
      ellipsis: true,
    },
    {
      title: 'Mother Album',
      dataIndex: 'album',
      key: 'album',
      align: 'center',
      width: 140,
      ellipsis: true,
      render: (album) => <span>{album?.albumName}</span>,
    },
    {
      title: 'Song Type',
      dataIndex: 'songType',
      key: 'songType',
      align: 'center',
      width: 90,
    },
    {
      title: 'Position No',
      dataIndex: 'positionNo',
      key: 'positionNo',
      align: 'center',
      width: 90,
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      align: 'center',
      width: 100,
    },
    {
      title: 'Downloadable',
      dataIndex: 'isdownloadable',
      key: 'isdownloadable',
      align: 'center',
      render: (_, record) => (
        <Checkbox disabled checked={record?.isdownloadable}></Checkbox>
      ),
      width: 130,
    },
    {
      title: 'Bits',
      align: 'center',
      width: 100,
      render: (_, record) => {
        return (
          <Row gutter={10}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='32 Bits File'>
                <Checkbox
                  disabled
                  checked={record?.media_sound?.bitFile32}
                ></Checkbox>
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='64 Bits File'>
                <Checkbox
                  disabled
                  checked={record?.media_sound?.bitFile64}
                ></Checkbox>
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='128 Bits File'>
                <Checkbox
                  disabled
                  checked={record?.media_sound?.bitFile128}
                ></Checkbox>
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='256 Bits File'>
                <Checkbox
                  disabled
                  checked={record?.media_sound?.bitFile256}
                ></Checkbox>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'grab',
        color: '#999',
      }}
    />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let tempSong = [...songs];
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        songs?.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      tempSong = newData;
      const payload = newData.map((item, i) => {
        return { ...item, positionNo: i + 1 };
      });
      setChangeOrder(true);
      setSongs(payload);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = songs?.findIndex(
      (x) => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <>
      <div className='d-flex align-items-center'>
        <h1 className='mb-0 font-size-28 mr-2'>{albumName}</h1>
      </div>

      {/* Table */}
      <div className='fix-table box-shadow-3 mt-3 border-radius-5'>
        <Table
          dataSource={songs}
          columns={columns}
          className='border-radius-5'
          pagination={false}
          loading={loading}
          rowKey='index'
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />

        {/* Button */}
        <div className='text-center bg-white width-1120 py-3'>
          <ButtonCom className='width-180 mr-2' onClick={() => navigate(-1)}>
            Back
          </ButtonCom>
          {changeOrder && (
            <ButtonCom
              type='primary'
              className='width-180'
              onClick={handleReorder}
            >
              Change Order
            </ButtonCom>
          )}
        </div>
      </div>
    </>
  );
};

export default ReorderAlbumSong;
