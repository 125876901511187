import React from 'react';

const TableImage = ({ image }) => {
  return (
    <>
      <img src={image || ' '} className='table-cover-img' alt='' />
    </>
  );
};

export default TableImage;
