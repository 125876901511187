import React from 'react';

import { Checkbox, Col, Form, Input, InputNumber, Row } from 'antd';

import SelectDropDown from '../../../../components/Select';
import ButtonCom from '../../../../components/Button/ButtonCom';

const CreateSection = ({
  form,
  onFinish,
  sizeType,
  tab,
  setSubTab,
  subTab,
  handleBack,
  id,
}) => {
  return (
    <>
      <Row justify='center' gutter={25} className='mt-2'>
        <Col
          xs={24}
          sm={24}
          md={20}
          lg={16}
          xl={16}
          className=' mt-3 gutter-row'
        >
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 8 },
              md: { span: 8 },
              lg: { span: 8 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 16 },
              md: { span: 16 },
              lg: { span: 16 },
            }}
            name='control-hooks'
            className='form-style'
          >
            <div className='bg-white box-shadow-3 border-radius-5 p-3 '>
              <h3 className='mb-0 font-size-25 mb-2'>Create New Section</h3>
              <Form.Item
                name='name'
                label='Section Name'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Section name is required !',
                  },
                ]}
              >
                <Input
                  type='text'
                  name='name'
                  placeholder='Section Name'
                  className='input-control'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name='positionNo'
                label='Position No.'
                labelAlign='left'
                extra={id ? '' : 'Leave blank for auto increment.'}
              >
                <InputNumber
                  min={0}
                  placeholder='Position No.'
                  className='input-control-number'
                />
              </Form.Item>
              <Form.Item
                name='isAvatarCircle'
                valuePropName='checked'
                label='Avatar Circle'
                labelAlign='left'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name='sizeType'
                label='Size Type'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'SizeType is required !',
                  },
                ]}
              >
                <SelectDropDown
                  options={sizeType}
                  isClearable
                  placeholder='Select Type'
                />
              </Form.Item>
              <Form.Item
                name='tabId'
                label='Select Tab'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Tab is required !',
                  },
                ]}
              >
                <SelectDropDown
                  options={tab}
                  isClearable
                  onChange={(e) => {
                    form.setFieldsValue({ subTabId: null });
                    setSubTab(e?.subTab);
                  }}
                  placeholder='Select Tab'
                />
              </Form.Item>
              <Form.Item
                name='subTabId'
                label='Select Sub-Tab'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Sub-Tab is required !',
                  },
                ]}
              >
                <SelectDropDown
                  options={subTab}
                  isClearable
                  placeholder='Select Sub-Tab'
                />
              </Form.Item>
              <div className='mt-4 text-center'>
                <ButtonCom className='width-180 mr-2' onClick={handleBack}>
                  Back
                </ButtonCom>
                <ButtonCom
                  htmlType='submit'
                  type='primary'
                  className='width-180'
                >
                  Next
                </ButtonCom>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CreateSection;
