import React from 'react';
import { Row, Col, Progress } from 'antd';

const Favorites = ({ title, nameTitle, countTitle, progress }) => {
  return (
    <>
      <h2 className='font-weight-700'>{title}</h2>
      <Row className='bg-light-gray p-1'>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h3 className='mb-0'>{nameTitle}</h3>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h3 className='mb-0'>{countTitle}</h3>
        </Col>
      </Row>
      {progress?.map((item, i) => {
        return (
          <Row className='px-1 mt-1 align-items-center' key={i}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h4 className='mb-0'>
                {item?.songTitle || item?.albumName || item?.name}
              </h4>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row key={i}>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <Progress
                    percent={
                      Math.round(
                        (progress[i]?.favorites_count /
                          progress[0]?.favorites_count) *
                          100
                      ) || item.count
                    }
                    showInfo={false}
                  />
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <span className='ml-2'>{item?.favorites_count}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default Favorites;
