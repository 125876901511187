import { methods, serviceMaker } from '../service';

const REPORTS_ALBUM = (
  limit,
  skip,
  search = null,
  sort = null,
  categoryId = null,
  subcategoryId = null,
  startDate = null,
  endDate = null,
  artistId = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (categoryId) {
    query['params']['categoryId'] = categoryId;
  }
  if (subcategoryId) {
    query['params']['subcategoryId'] = subcategoryId;
  }
  if (startDate) {
    query['params']['startDate'] = startDate;
  }
  if (endDate) {
    query['params']['endDate'] = endDate;
  }
  if (artistId) {
    query['params']['artistId'] = artistId;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  }
  return serviceMaker(`reports/albums`, methods.GET, query);
};

const REPORTS_SONG = (
  limit,
  skip,
  search = null,
  sort = null,
  startDate = null,
  endDate = null,
  artistId = null,
  albumId = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (startDate) {
    query['params']['startDate'] = startDate;
  }
  if (endDate) {
    query['params']['endDate'] = endDate;
  }
  if (artistId) {
    query['params']['artistId'] = artistId;
  }
  if (albumId) {
    query['params']['albumId'] = albumId;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  }
  return serviceMaker(`reports/songs`, methods.GET, query);
};

const GET_ARTISTS = (limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`artists`, methods.GET, query);
};

const GET_ALBUMS = (limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`albums`, methods.GET, query);
};

const REPORTS_USER = (
  limit,
  skip,
  search,
  sort,
  startDate,
  endDate,
  gender,
  countryCode
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (startDate) {
    query['params']['startDate'] = startDate;
  }
  if (endDate) {
    query['params']['endDate'] = endDate;
  }
  if (gender) {
    query['params']['gender'] = gender;
  }
  if (countryCode) {
    query['params']['countryCode'] = countryCode;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  }
  return serviceMaker(`reports/users`, methods.GET, query);
};

const REPORTS_ADVERTISE = (
  limit,
  skip,
  search = null,
  sort = null,
  startDate = null,
  endDate = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (startDate) {
    query['params']['startDate'] = startDate;
  }
  if (endDate) {
    query['params']['endDate'] = endDate;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  }
  return serviceMaker(`reports/ads`, methods.GET, query);
};

const REPORTS_ARTIST = (
  limit,
  skip,
  search = null,
  sort = null,
  startDate = null,
  endDate = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (startDate) {
    query['params']['startDate'] = startDate;
  }
  if (endDate) {
    query['params']['endDate'] = endDate;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  }
  return serviceMaker(`reports/artists`, methods.GET, query);
};

const REPORTS_SUBCATEGORY = (
  limit,
  skip,
  search = null,
  sort = null,
  categoryId = null,
  startDate = null,
  endDate = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (startDate) {
    query['params']['startDate'] = startDate;
  }
  if (endDate) {
    query['params']['endDate'] = endDate;
  }
  if (categoryId) {
    query['params']['categoryId'] = categoryId;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  }
  return serviceMaker(`reports/subcategory`, methods.GET, query);
};

const REPORTS_CATEGORY = (
  limit,
  skip,
  search = null,
  sort = null,
  startDate = null,
  endDate = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (startDate) {
    query['params']['startDate'] = startDate;
  }
  if (endDate) {
    query['params']['endDate'] = endDate;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  }
  return serviceMaker(`reports/category`, methods.GET, query);
};

const REPORTS_NOTIFICATION = (
  limit,
  skip,
  search = null,
  sort = null,
  startDate = null,
  endDate = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (startDate) {
    query['params']['startDate'] = startDate;
  }
  if (endDate) {
    query['params']['endDate'] = endDate;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  }
  return serviceMaker(`reports/notifications`, methods.GET, query);
};

export const REPORTS = {
  REPORTS_ALBUM,
  REPORTS_SONG,
  GET_ARTISTS,
  GET_ALBUMS,
  REPORTS_USER,
  REPORTS_ADVERTISE,
  REPORTS_ARTIST,
  REPORTS_CATEGORY,
  REPORTS_NOTIFICATION,
  REPORTS_SUBCATEGORY,
};
