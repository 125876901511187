import React, { useEffect, useRef, useState } from 'react';

import { Row, Col, DatePicker, Checkbox, Input, Table, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import PaginationCom from '../../../components/PaginationCom';
import Spinner from '../../../components/Spinner';

import { REPORTS } from '../../../service';

import { toast } from 'react-toastify';

import { tableSortIconToggle } from '../../../utility/tableSortingForSongAndAlbum';
import SelectWithPagination from '../../../components/SelectWithPagination';
import {
  ExportExcel,
  ExportPdf,
  handleFileName,
} from '../../../utility/Export/Export';

import { CSVLink } from 'react-csv';

const { RangePicker } = DatePicker;

const SongTab = () => {
  const [songList, setSongList] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);
  const [sortedInfo, setSortedInfo] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueOfRangePicker, setValueOfRangePicker] = useState(null);

  const [artist, setArtist] = useState(null);
  const [album, setAlbum] = useState(null);

  const [topDownload, setTopDownload] = useState(null);
  const [favoriteCount, setFavoriteCount] = useState(null);
  const [playCount, setPlayCount] = useState(null);

  const [csv, setCsv] = useState([]);

  const [spinnerValue, setSpinnerValue] = useState(false);

  const csvLink = useRef();

  const headers = [
    { label: 'Song Id', key: 'id' },
    { label: 'Song Title', key: 'songTitle' },
    { label: 'CMS Path', key: 'cmsPath' },
    { label: 'Mother Album', key: 'motherAlbum' },
    { label: 'File Name', key: 'path' },
    { label: 'Downloadable', key: 'isdownloadable' },
    { label: '32 Bits', key: 'bitFile32' },
    { label: '64 Bits', key: 'bitFile64' },
    { label: '128 Bits', key: 'bitFile128' },
    { label: '256 Bits', key: 'bitFile256' },
    { label: 'Favorite Count', key: 'favorite_count' },
    { label: 'Download Count', key: 'download_count' },
    { label: 'Played Count', key: 'played_count' },
  ];

  const getSongReport = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    startDate = null,
    endDate = null,
    artistId = null,
    albumId = null
  ) => {
    setLoading(true);
    try {
      const res = await REPORTS.REPORTS_SONG(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        startDate,
        endDate,
        artistId,
        albumId
      );
      if (res.status === 1 || res.status === '1') {
        setSongList(res);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const loadOptionsOfArtist = async (search, prevOptions, { skip }) => {
    const res = await REPORTS.GET_ARTISTS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const loadOptionsOfAlbum = async (search, prevOptions, { skip }) => {
    const res = await REPORTS.GET_ALBUMS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.albumName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSorting = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
      setTopDownload(false);
      setFavoriteCount(false);
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
      if (sorter?.field === 'download_count') {
        setTopDownload(true);
      }
      if (sorter?.field === 'favorite_count') {
        setFavoriteCount(true);
      }
      if (sorter?.field === 'played_count') {
        setPlayCount(true);
      }
    } else {
      setSort({ createdAt: -1 });
      if (sorter?.field === 'download_count') {
        setTopDownload(false);
      }
      if (sorter?.field === 'favorite_count') {
        setFavoriteCount(false);
      }
      if (sorter?.field === 'played_count') {
        setPlayCount(false);
      }
    }
  };

  const exportData = async () => {
    setSpinnerValue(true);
    try {
      const res = await REPORTS.REPORTS_SONG(
        '-1',
        0,
        search,
        sort,
        startDate,
        endDate,
        artist?.value,
        album?.value
      );
      if (res.status === 1 || res.status === '1') {
        return res;
      }
    } catch (error) {
      setSpinnerValue(false);
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setSpinnerValue(false);
    }
  };

  const generatePDF = async () => {
    let res = await exportData();
    const pdftableColumn = [
      'Song Title',
      'Downloadable',
      '32 Bits',
      '64 Bits',
      '128 Bits',
      '256 Bits',
      'Favorite Count',
      'Download Count',
      'Played Count',
    ];
    const pdftableRows = [];
    res?.data.forEach((item) => {
      const albumReportsListData = [
        item.songTitle,
        item.isdownloadable,
        item.cmsPath,
        item?.album?.albumName,
        item.bitFile32 ? true : false,
        item.bitFile64 ? true : false,
        item.bitFile128 ? true : false,
        item.bitFile256 ? true : false,
        item.favorite_count,
        item.download_count,
        item.played_count,
      ];
      pdftableRows.push(albumReportsListData);
    });
    ExportPdf(pdftableColumn, pdftableRows, 'songReport');
  };

  const generateExcel = async () => {
    const res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data?.map((item) => {
        return {
          'Song Id': item.id,
          'Song Title': item.songTitle,
          cmsPath: item.cmsPath,
          'Mother Album': item?.album?.albumName,
          'File Name': item.path,
          Downloadable: item.isdownloadable,
          '32 Bits': item.bitFile32 ? true : false,
          '64 Bits': item.bitFile64 ? true : false,
          '128 Bits': item.bitFile128 ? true : false,
          '256 Bits': item.bitFile256 ? true : false,
          'Favorite Count': item.favorite_count,
          'Download Count': item.download_count,
          'Played Count': item.played_count,
        };
      });
      ExportExcel(temp, 'songReport');
    }
  };

  const handleDownloadCsv = async () => {
    const res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data.map((item) => {
        return {
          id: item.id,
          songTitle: item.songTitle,
          cmsPath: item.cmsPath,
          motherAlbum: item?.album?.albumName,
          path: item.path,
          isdownloadable: item.isdownloadable,
          bitFile32: item.bitFile32 ? true : false,
          bitFile64: item.bitFile64 ? true : false,
          bitFile128: item.bitFile128 ? true : false,
          bitFile256: item.bitFile256 ? true : false,
          favorite_count: item.favorite_count,
          download_count: item.download_count,
          played_count: item.played_count,
        };
      });
      setCsv([...temp]);
      csvLink.current.link.click();
      setSpinnerValue(false);
    }
  };

  const handleChangeOfSearch = (e) => {
    if (!e?.target?.value) {
      setSearch(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const enterSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const handleChangeOfMostFavoriteSongs = (e) => {
    setCurrentPage(1);
    setFavoriteCount(e.target.checked);
    setTopDownload(null);
    setPlayCount(null);
    if (e.target.checked) {
      setSort({
        favorite_count: -1,
      });
      setSortedInfo({
        order: 'descend',
        columnKey: 'favorite_count',
      });
    } else {
      setSort({ createdAt: -1 });
      setSortedInfo({});
    }
  };

  const handleChangeOfMostPlayed = (e) => {
    setCurrentPage(1);
    setPlayCount(e.target.checked);
    setTopDownload(null);
    setFavoriteCount(null);
    if (e.target.checked) {
      setSort({
        played_count: -1,
      });
      setSortedInfo({
        order: 'descend',
        columnKey: 'played_count',
      });
    } else {
      setSort({ createdAt: -1 });
      setSortedInfo({});
    }
  };

  const handleChangeOfTopDownloadedSongs = (e) => {
    setCurrentPage(1);
    setTopDownload(e.target.checked);
    setFavoriteCount(null);
    setPlayCount(null);
    if (e.target.checked) {
      setSort({
        favorite_count: -1,
      });
      setSortedInfo({
        order: 'descend',
        columnKey: 'download_count',
      });
    } else {
      setSort({ createdAt: -1 });
      setSortedInfo({});
    }
  };

  const clearAllFilter = () => {
    setCurrentPage(1);
    setPageSize(10);
    setSearch(null);
    setTempSearch(null);
    setSort(null);
    setStartDate(null);
    setEndDate(null);
    setTopDownload(null);
    setFavoriteCount(null);
    setPlayCount(null);
    setSortedInfo({});
    setValueOfRangePicker(null);
    setAlbum(null);
    setArtist(null);
  };

  const handleChangeOfRangePicker = (e) => {
    setCurrentPage(1);
    setValueOfRangePicker(e);
    if (e) {
      setStartDate(
        new Date(e[0]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
      setEndDate(
        new Date(e[1]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  useEffect(() => {
    getSongReport(
      currentPage,
      pageSize,
      search,
      sort,
      startDate,
      endDate,
      artist?.value,
      album?.value
    );
  }, [
    album?.value,
    artist?.value,
    currentPage,
    search,
    endDate,
    pageSize,
    sort,
    startDate,
  ]);

  const columns = [
    {
      title: (column) => tableSortIconToggle('Song Title', 'songTitle', column),
      dataIndex: 'songTitle',
      key: 'songTitle',
      sortDirections: ['ascend', 'descend'],
      sorter: songList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'songTitle' ? sortedInfo.order : undefined,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggle('CMS Path', 'cmsPath', column),
      dataIndex: 'cmsPath',
      key: 'cmsPath',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'cmsPath' ? sortedInfo.order : undefined,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggle('Mother Album', 'album', column),
      dataIndex: 'album',
      key: 'album',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songList?.data?.length > 1 ? true : false,
      ellipsis: true,
      render: (album) => <span>{album?.albumName}</span>,
    },
    {
      title: (column) =>
        tableSortIconToggle('Downloadable', 'isdownloadable', column),
      dataIndex: 'isdownloadable',
      key: 'isdownloadable',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: songList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'isdownloadable'
          ? sortedInfo.order
          : undefined,
      render: (isdownloadable) => {
        return <Checkbox disabled checked={isdownloadable}></Checkbox>;
      },
      width: 170,
    },
    {
      title: 'Bits',
      align: 'center',
      width: 140,
      render: (_, record) => {
        return (
          <Row gutter={10}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='32 Bits File'>
                <Checkbox disabled checked={record?.bitFile32}></Checkbox>
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='64 Bits File'>
                <Checkbox disabled checked={record?.bitFile64}></Checkbox>
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='128 Bits File'>
                <Checkbox disabled checked={record?.bitFile128}></Checkbox>
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='256 Bits File'>
                <Checkbox disabled checked={record?.bitFile256}></Checkbox>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: (column) =>
        tableSortIconToggle('Favourite Count', 'favorite_count', column),
      dataIndex: 'favorite_count',
      key: 'favorite_count',
      align: 'center',
      sorter: songList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'favorite_count'
          ? sortedInfo.order
          : undefined,
      width: 180,
    },
    {
      title: (column) =>
        tableSortIconToggle('Download Count', 'download_count', column),
      dataIndex: 'download_count',
      key: 'download_count',
      align: 'center',
      sorter: songList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'download_count'
          ? sortedInfo.order
          : undefined,
      width: 180,
    },
    {
      title: (column) =>
        tableSortIconToggle('Played Count', 'played_count', column),
      dataIndex: 'played_count',
      key: 'played_count',
      align: 'center',
      sorter: songList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'played_count' ? sortedInfo.order : undefined,
      width: 160,
    },
  ];

  return (
    <>
      {/* Inside Header */}
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <div className='bg-white box-shadow-3 border-radius-5 px-3 pt-3 pb-1'>
            <Row gutter={20} className='align-items-center'>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <Input
                  type='search'
                  placeholder='Start Typing to find Song'
                  onChange={(e) => handleChangeOfSearch(e)}
                  onKeyPress={(e) => {
                    if (e?.key === 'Enter' && e.target.value) {
                      enterSearch();
                    }
                  }}
                  suffix={
                    <SearchOutlined
                      style={{ color: '#8f9bb3' }}
                      onClick={enterSearch}
                    />
                  }
                  value={tempSearch}
                  className='input-control'
                  allowClear
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <SelectWithPagination
                  placeholder='Select Artist'
                  loadOptions={loadOptionsOfArtist}
                  onChange={(e) => setArtist(e)}
                  additional={{
                    skip: 0,
                  }}
                  value={artist}
                  isClearable
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <SelectWithPagination
                  placeholder='Select Album'
                  loadOptions={loadOptionsOfAlbum}
                  onChange={(e) => setAlbum(e)}
                  additional={{
                    skip: 0,
                  }}
                  value={album}
                  isClearable
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <RangePicker
                  className='input-control w-100'
                  onChange={(e) => handleChangeOfRangePicker(e)}
                  value={valueOfRangePicker}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <div className='d-flex align-items-center'>
                  <h3 className='font-size-16 mb-0 mr-1'>
                    Most Favourite Songs
                  </h3>
                  <Checkbox
                    className='h-25px '
                    onChange={(e) => handleChangeOfMostFavoriteSongs(e)}
                    checked={favoriteCount}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <div className='d-flex'>
                  <h3 className='font-size-16 mb-0 mr-1'>Most Played</h3>
                  <Checkbox
                    className='h-25px'
                    onChange={(e) => handleChangeOfMostPlayed(e)}
                    checked={playCount}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <div className='d-flex align-items-center'>
                  <h3 className='font-size-16 mb-0 mr-1'>
                    Top Downloaded Songs
                  </h3>
                  <Checkbox
                    className='h-25px'
                    onChange={(e) => handleChangeOfTopDownloadedSongs(e)}
                    checked={topDownload}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className='gutter-row mb-1'
              >
                <div className='d-flex flex-wrap align-items-center'>
                  <div className='mb-1 mr-2 mt-1 bg-white font-weight-600'>
                    Export
                  </div>
                  <div>
                    <button
                      className='bg-light-gray font-weight-600 border-0 border-radius-3 mb-1 py-8px px-2 mr-1 cursor-pointer'
                      onClick={handleDownloadCsv}
                    >
                      CSV
                    </button>
                    <CSVLink
                      data={csv}
                      headers={headers}
                      filename={handleFileName('songReport', 'csv')}
                      ref={csvLink}
                      className='text-black font-weight-600'
                    ></CSVLink>
                  </div>
                  <button
                    className='bg-light-gray font-weight-600 border-0 border-radius-3 mb-1 py-8px px-2 mr-1 cursor-pointer'
                    onClick={generateExcel}
                  >
                    Excel
                  </button>
                  <button
                    className='bg-light-gray font-weight-600 border-0 border-radius-3 mb-1 py-8px px-2 mr-1 cursor-pointer'
                    onClick={generatePDF}
                  >
                    Pdf
                  </button>
                  <h3 className='clear-all mt-1' onClick={clearAllFilter}>
                    Clear All
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* Tables Data */}
      <div className='fix-table mt-3 box-shadow-3 border-radius-5'>
        <Table
          columns={columns}
          dataSource={songList?.data}
          pagination={false}
          loading={loading}
          rowKey='id'
          onChange={handleSorting}
        />
        {/* Pagination */}
        {songList?.total > 10 && (
          <div className='text-center bg-white py-2 width-1120 '>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={songList?.total}
            />
          </div>
        )}

        {spinnerValue && <Spinner />}
      </div>
    </>
  );
};

export default SongTab;
