import React, { useEffect, useState } from 'react';

import { Input, Upload, Row, Col, DatePicker, Switch, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import ButtonCom from '../../../../components/Button/ButtonCom';

import { ALBUMS } from '../../../../service/index';
import { BASE_URL } from '../../../../service/constant';

import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import moment from 'moment';

const CreateAlbum = ({ setCurrent, scope }) => {
  const [publishDate, setPublishDate] = useState(null);

  const [fileList, setFileList] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();

  const [form] = Form.useForm();

  const handleBack = () => {
    localStorage.removeItem('step1');
    localStorage.removeItem('step2');
    localStorage.removeItem('file');
    navigate('/albums');
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL}/uploads-image`,
    headers: {
      authorization: localStorage.getItem('jalso-token'),
    },
    beforeUpload(file) {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn('File Already Uploaded! Please delete that.');
        return false;
      }
      const type =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg';
      if (!type) {
        toast.error('Only .jpeg, .png, .jpg files are allowed!');
      }
      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === 'error') {
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove(info) {
      setFileList([]);
    },
  };

  const onFinish = (value) => {
    const payload = {
      ...value,
      label: value?.albumName,
      value: value?.albumName,
    };
    payload.media_item =
      value?.media_item?.file?.response?.data?.media || value?.media_item;
    if (publishDate) {
      payload.publishDate = publishDate;
    }
    localStorage.setItem('step1', JSON.stringify(payload));
    setCurrent(1);
  };

  useEffect(() => {
    const value = JSON.parse(localStorage.getItem('step1')) || '';
    const getSingleAlbum = async () => {
      form.setFieldsValue({
        ...value,
        releasedate: moment(value?.releasedate),
        media_item: value?.media_item,
      });
      setPublishDate(value?.publishDate);
    };
    if (value && fileList.length === 0) {
      getSingleAlbum();
    }
  }, [fileList.length, form]);

  useEffect(() => {
    const value = JSON.parse(localStorage.getItem('step1')) || '';
    const getSingleAlbum = async () => {
      const tempFileList = [...fileList];
      try {
        const res = await ALBUMS.SINGLE_ALBUMS(id);
        if (res.status === 1 || res.status === '1') {
          form.setFieldsValue({
            ...res.data,
            releasedate: moment(res.data?.releasedate),
            media_item: res?.data?.media_item,
          });
          setPublishDate(res.data.publishDate);
          if (res?.data?.media_item) {
            tempFileList.push({
              uid: '-1',
              name: 'cover image',
              status: 'done',
              url: res?.data?.media_item?.image,
              thubmUrl: res?.data?.media_item?.image,
            });
          }
          setFileList(tempFileList);
        }
      } catch (err) {
        toast.error(err.message || 'Something went wrong!');
      }
    };
    if (id && value === '' && fileList.length === 0 && scope === 'edit-album') {
      getSingleAlbum();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id, scope]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
          md: { span: 8 },
          lg: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
          md: { span: 16 },
          lg: { span: 16 },
        }}
        labelAlign='left'
        name='control-hooks'
        className='form-style'
      >
        <Row justify='center' gutter={0} className='mt-4'>
          <Col xs={24} sm={24} md={20} lg={15} xl={15} className='gutter-row'>
            <InsideHeader className='p-3'>
              <h3 className='font-size-25 mb-2'>
                {scope === 'edit-album' && 'Edit Album'}
                {scope === 'add-album' && 'Create New Album'}
              </h3>
              <Form.Item
                name='albumName'
                label='Album Name'
                rules={[
                  {
                    required: true,
                    message: 'Album Name is required !',
                  },
                ]}
              >
                <Input
                  className='input-control'
                  name='albumName'
                  placeholder='Album Name'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name='subtitle'
                label='Subtitle'
                rules={[
                  {
                    required: true,
                    message: 'Subtitle is required !',
                  },
                ]}
              >
                <Input
                  className='input-control'
                  name='subtitle'
                  placeholder='Subtitle'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name='releasedate'
                label='Release Date'
                rules={[
                  {
                    required: true,
                    message: 'Release Date is required !',
                  },
                ]}
              >
                <DatePicker
                  className='input-control w-100'
                  placeholder='Release Date'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name='media_item'
                label='Cover Images'
                valuePropName='filelist'
              >
                <Upload
                  {...props}
                  accept='.png, .jpg, .jpeg'
                  className='file-upload'
                  listType='picture'
                >
                  <ButtonCom type='primary' icon={<UploadOutlined />}>
                    Click to Upload
                  </ButtonCom>
                </Upload>
              </Form.Item>
              <Form.Item
                name='isdownloadable'
                label='Downloadable'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>

              <div className='text-center mt-3'>
                <ButtonCom onClick={handleBack} className='mr-2 width-180'>
                  Back
                </ButtonCom>
                {scope === 'edit-album' && (
                  <ButtonCom
                    type='primary'
                    htmlType='submit'
                    className='width-180'
                  >
                    Publish
                  </ButtonCom>
                )}
                {scope === 'add-album' && (
                  <ButtonCom
                    type='primary'
                    htmlType='submit'
                    className='width-180'
                  >
                    Next
                  </ButtonCom>
                )}
              </div>
            </InsideHeader>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateAlbum;
