import React, { useEffect, useState } from 'react';

import { Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import InsideHeader from '../../../components/InsideHeader/InsideHeader';
import ManageCard from '../../../components/Section/Manage/ManageCard';
import EmptyScreen from '../../../components/EmptyScreen';
import SelectDropDown from '../../../components/Select';
import ButtonCom from '../../../components/Button/ButtonCom';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SECTION } from '../../../service';

import { toast } from 'react-toastify';

const Manage = ({ permissions }) => {
  const [singleSubTab, setSingleSubTab] = useState([]);

  const [tab, setTab] = useState([]);
  const [tabValue, setTabValue] = useState(null);

  const [subTab, setSubTab] = useState([]);
  const [subTabValue, setSubTabValue] = useState(null);

  const [search, setSearch] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const getManageData = async (id) => {
    try {
      const res = await SECTION.SINGLE_SUB_TAB(id);
      if (res.status === 1) {
        setSingleSubTab(res.data.sections);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const handleSearch = () => {
    if (search) {
      const filter = singleSubTab.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setSingleSubTab(filter);
    }
  };

  const handleChangeOfSearch = (e) => {
    if (!e.target.value) {
      getManageData(subTabValue?.value);
    }
    setSearch(e.target.value);
  };

  const handleChangeOfTab = (e) => {
    setTabValue(e);
    setSubTabValue(null);
    setSubTab(e?.subTab);
    setSingleSubTab([]);
  };

  const handleChangeOfSubTab = (e) => {
    setSubTabValue(e);
  };

  useEffect(() => {
    if (subTabValue?.value) {
      getManageData(subTabValue?.value);
    }
  }, [subTabValue?.value]);

  useEffect(() => {
    const getTab = async () => {
      try {
        const res = await SECTION.TAB();
        if (res.status === 1) {
          const data = res?.data?.map((item) => {
            const subTab = item?.sub_tabs?.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            });
            return {
              label: item.name,
              value: item.id,
              subTab,
            };
          });
          setTab(data);

          const filterTab = data.filter(
            (item) => item.value === Number(location.state.tabId)
          )[0];
          setTabValue(filterTab);

          setSubTab(filterTab.subTab);

          const tempSubTab = data
            ?.reduce((pre, item) => [...pre, ...item.subTab], [])
            .filter((item) => item.value === Number(id))[0];
          setSubTabValue(tempSubTab);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong!');
      }
    };
    getTab();
  }, [id, location.state.tabId]);

  return (
    <>
      <InsideHeader className='mb-3 pb-1'>
        <Row gutter={20} className='align-items-center'>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            className='gutter-row mb-1'
          >
            <Input
              placeholder='Start Typing to Find Section'
              suffix={
                <SearchOutlined
                  style={{ color: '#8f9bb3' }}
                  onClick={handleSearch}
                />
              }
              onChange={(e) => handleChangeOfSearch(e)}
              onKeyPress={(e) => {
                if (e?.key === 'Enter' && e.target.value) {
                  handleSearch();
                }
              }}
              className='input-control'
              allowClear
              value={search}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            className='gutter-row mb-1'
          >
            {(permissions?.isCreate || permissions?.isCreate === undefined) && (
              <div className='d-flex flex-wrap align-items-center  justify-content-md-left justify-content-end'>
                <ButtonCom
                  type='primary'
                  onClick={() => {
                    localStorage.setItem('tabId', location.state.tabId);
                    localStorage.setItem('subTabId', Number(id));
                    localStorage.removeItem('section-step-1');
                    navigate('/sections/add');
                  }}
                >
                  Create New Section
                </ButtonCom>
              </div>
            )}
          </Col>
        </Row>
      </InsideHeader>

      <InsideHeader className='pt-2 pb-0'>
        <Row gutter={20} className='align-items-center'>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} className='gutter-row'>
            <SelectDropDown
              placeholder='Select Tab'
              options={tab}
              value={tabValue}
              onChange={(e) => handleChangeOfTab(e)}
              className='mb-2 w-100'
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} className='gutter-row'>
            <SelectDropDown
              placeholder='Select Sub-Tab'
              options={subTab}
              value={subTabValue}
              onChange={(e) => handleChangeOfSubTab(e)}
              className='mb-2 w-100'
            />
          </Col>
        </Row>
      </InsideHeader>

      {singleSubTab?.length > 0 && (
        <ManageCard
          getManageData={getManageData}
          singleSubTab={singleSubTab}
          tabId={location.state.tabId}
          subTabId={Number(id)}
          search={search}
          handleSearch={handleSearch}
          permissions={permissions}
        />
      )}

      {singleSubTab?.length <= 0 && (
        <div className='section-empty-screen'>
          <EmptyScreen />
        </div>
      )}
    </>
  );
};

export default Manage;
