import React from 'react';

import { Row, Col, Checkbox, Table } from 'antd';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { SONGS } from '../../../../service';

import ButtonCom from '../../../../components/Button/ButtonCom';

const ReviewData = ({ setCurrent }) => {
  const navigate = useNavigate();
  const album = JSON.parse(localStorage.getItem('album-record')) || [];
  const song = JSON.parse(localStorage.getItem('song-record')) || [];

  const columns = [
    {
      title: 'Song Title',
      dataIndex: 'songTitle',
      key: 'songTitle',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      render: (record, item) => {
        return (
          <>
            <span>{record}</span>
          </>
        );
      },
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      align: 'center',
    },
    {
      title: 'Downloadable',
      dataIndex: 'downloadable',
      key: 'downloadable',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Checkbox disabled checked={record?.downloadable}></Checkbox>
          </>
        );
      },
    },
    {
      title: '32 bits',
      dataIndex: 'bits_32',
      key: 'bits_32',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Checkbox
              disabled
              checked={record?.media_sound?.bitFile32}
            ></Checkbox>
          </>
        );
      },
    },
    {
      title: '64 bits',
      dataIndex: 'bits_64',
      key: 'bits_64',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Checkbox
              disabled
              checked={record?.media_sound?.bitFile64}
            ></Checkbox>
          </>
        );
      },
    },
    {
      titile: '128 bits',
      dataIndex: 'bits_128',
      key: 'bits_128',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Checkbox
              disabled
              checked={record?.media_sound?.bitFile128}
            ></Checkbox>
          </>
        );
      },
    },
    {
      title: '256 bits',
      dataIndex: 'bits_256',
      key: 'bits_256',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Checkbox
              disabled
              checked={record?.media_sound?.bitFile256}
            ></Checkbox>
          </>
        );
      },
    },
  ];

  const selectAlbumColumn = [
    {
      title: 'Cover Image',
      dataIndex: 'media_item',
      key: 'media_item',
      align: 'center',
      render: (media_item) => {
        return (
          <img
            src={media_item?.image || ' '}
            className='table-cover-img'
            alt=''
          />
        );
      },
      width: '130px',
    },
    {
      title: 'Album Name',
      dataIndex: 'albumName',
      key: 'albumName',
      align: 'center',
      width: '250px',
    },
    {
      title: 'Subtitle',
      dataIndex: 'subtitle',
      key: 'Subtitle',
      align: 'center',
      width: '200px',
    },
    {
      title: 'Downloadable',
      dataIndex: 'isdownloadable',
      key: 'isdownloadable',
      align: 'center',
      render: (isdownloadable) => {
        return (
          <span>
            <Checkbox checked={isdownloadable} disabled />
          </span>
        );
      },
      width: '160px',
    },
    {
      title: 'Release date',
      key: 'releasedate',
      dataIndex: 'releasedate',
      align: 'center',
      width: '160px',
      render: (releasedate) => {
        return (
          <span>
            {new Date(releasedate).toLocaleString('en-US', {
              dateStyle: 'medium',
            })}
          </span>
        );
      },
    },
  ];

  const handleBack = () => {
    setCurrent(1);
  };

  const handleCreate = async () => {
    const payload = [];
    album.forEach((album) => {
      song.forEach((song) => {
        payload.push({
          albumId: album.id,
          songId: song.id,
        });
      });
    });
    try {
      const res = await SONGS.CREATE_ALBUMS_SONG(payload);
      if (res.status === 1 || res.status === '1') {
        localStorage.removeItem('add-album-1');
        localStorage.removeItem('song-record');
        localStorage.removeItem('add-album-2');
        localStorage.removeItem('album-record');
        localStorage.removeItem('add-album-already');
        navigate('/songs');
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  return (
    <>
      {/* Selected Songs */}
      <Row justify='center' gutter={0}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <h3 className='mb-0  mt-3 font-size-25'>Selected Songs</h3>
          <div className='fix-table box-shadow-3 border-radius-5 mt-1'>
            <Table
              dataSource={song}
              columns={columns}
              pagination={false}
              rowKey='id'
            />
          </div>
        </Col>
      </Row>

      {/* Selected Album */}
      <Row justify='center' gutter={0}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <h3 className='mb-0  mt-2 font-size-25'>Selected Albums</h3>
          <div className='fix-table box-shadow-3 mt-1 border-radius-5'>
            <Table
              dataSource={album}
              columns={selectAlbumColumn}
              pagination={false}
              rowKey='id'
            />
            {/* Button */}
            <div className='text-center bg-white py-3 width-1120 '>
              <ButtonCom className='mr-2 width-180' onClick={handleBack}>
                Back
              </ButtonCom>
              <ButtonCom
                type='primary'
                className='width-180'
                onClick={handleCreate}
              >
                Create
              </ButtonCom>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ReviewData;
