import React, { useEffect, useState } from 'react';

import { Row, Col, Table, Checkbox, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import InsideHeader from '../../../components/InsideHeader/InsideHeader';
import PaginationCom from '../../../components/PaginationCom';
import TableImage from '../../../components/Image/TableImage';
import InfoDrawer from '../../Albums/InfoDrawer/InfoDrawer';
import SelectWithPagination from '../../../components/SelectWithPagination';
import ButtonCom from '../../../components/Button/ButtonCom';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { SONGS } from '../../../service';

const SearchSongs = ({ permissions }) => {
  const [albumList, setAlbumList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [albumSongDeleteId, setAlbumSongDeleteId] = useState([]);
  const [visible, setVisible] = useState(false);

  const [song, setSong] = useState(null);

  const [infoDetails, setInfoDetails] = useState([]);

  const [disabledBtn, setDisabledBtn] = useState(true);

  const navigate = useNavigate();

  const getListOfAlbums = async (page = 1, pageSize = 10, id, sort) => {
    setLoading(true);
    try {
      const res = await SONGS.GET_SELECT_SONG_ALBUM(
        pageSize,
        (page - 1) * pageSize,
        id,
        sort
      );
      if (res.status === 1 || res.status === '1') {
        const resetData = [];
        res.data.forEach((item) => {
          if (item.album) {
            resetData.push({ ...item.album, albumSong: item });
          }
        });
        setAlbumList({ total: res.total, data: resetData });
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const loadOptionsOfSong = async (search, prevOptions, { skip }) => {
    const res = await SONGS.ALL_SONGS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.songTitle,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleChangeOfSong = (e) => {
    setCurrentPage(1);
    setSong(e);
    if (!e) {
      setAlbumList({});
      setSong(null);
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (_, selectedRows) => {
      const selectRowsId = selectedRows.map((item) => {
        return { id: item.albumSong.id, deletedAt: new Date() };
      });
      if (selectedRows.length > 0) {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
      setAlbumSongDeleteId(selectRowsId);
    },
  };

  const handleDelete = async () => {
    try {
      const res = await SONGS.REMOVE_ALBUM_SONG(albumSongDeleteId);
      if (res.status === 1 || res.status === '1') {
        let page = currentPage;
        if (albumList?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          getListOfAlbums(page, pageSize, song?.value, sort);
        }
        toast.success('Record deleted successfully !');
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    if (song?.value) {
      getListOfAlbums(currentPage, pageSize, song?.value, sort);
    }
  }, [currentPage, pageSize, song?.value, sort]);

  const columns = [
    {
      title: 'Cover Image',
      dataIndex: 'media_item',
      key: 'media_item',
      align: 'center',
      render: (media_item) => <TableImage image={media_item?.image} />,
      width: 130,
    },
    {
      title: 'Albums Title',
      dataIndex: 'albumName',
      key: 'albumName',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: 'Subtitle',
      dataIndex: 'subtitle',
      key: 'Subtitle',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: 'Downloadable',
      dataIndex: 'isdownloadable',
      key: 'isdownloadable',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      render: (isdownloadable) => {
        return <Checkbox checked={isdownloadable} disabled />;
      },
      width: 160,
    },
    {
      title: 'Release Date',
      key: 'releasedate',
      dataIndex: 'releasedate',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      width: 160,
      render: (releasedate) => {
        return (
          <span>
            {new Date(releasedate).toLocaleString('en-US', {
              dateStyle: 'medium',
            })}
          </span>
        );
      },
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 100,
      render: (_, record) => {
        return (
          <Tooltip title='View Details'>
            <InfoCircleOutlined
              className='font-size-20 info-btn-hover cursor-pointer mx-1'
              onClick={() => {
                setVisible(true);
                setInfoDetails(record);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <InsideHeader>
        <h3 className='mb-0 font-size-25'>Search Songs in Albums</h3>
        <Row gutter={0} className='align-items-center'>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={13}
            xl={13}
            className='gutter-row mt-1'
          >
            <SelectWithPagination
              placeholder='Select Song'
              loadOptions={loadOptionsOfSong}
              onChange={handleChangeOfSong}
              value={song}
              additional={{
                skip: 0,
              }}
              isClearable
            />
          </Col>
        </Row>
      </InsideHeader>

      <div className='fix-table box-shadow-3 border-radius-5 mt-3'>
        <Table
          rowSelection={{
            type: <Checkbox />,
            ...rowSelection,
          }}
          dataSource={albumList?.data}
          columns={columns}
          pagination={false}
          loading={loading}
          rowKey='id'
          onChange={onChange}
        />

        <div className='text-center bg-white py-3 width-1120 '>
          <ButtonCom className='mr-2 width-180' onClick={() => navigate(-1)}>
            Back
          </ButtonCom>
          {disabledBtn
            ? (permissions?.isDelete ||
                permissions?.isDelete === undefined) && (
                <ButtonCom type='primary'>
                  Delete song from the selected Albums
                </ButtonCom>
              )
            : (permissions?.isDelete ||
                permissions?.isDelete === undefined) && (
                <ButtonCom type='primary' onClick={handleDelete}>
                  Delete song from the selected Albums
                </ButtonCom>
              )}
        </div>

        {albumList?.total > 10 && (
          <div className='text-center bg-white px-3 pb-3 width-1120 '>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={albumList?.total}
            />
          </div>
        )}
      </div>

      <InfoDrawer
        setVisible={setVisible}
        visible={visible}
        infoDeatils={infoDetails}
      />
    </>
  );
};

export default SearchSongs;
