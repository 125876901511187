import { useState, useEffect } from 'react';

import { Form, Input, Radio } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import { ALBUMS, NOTIFICATION, SECTION } from '../../../../service/index';

import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import ButtonCom from '../../../../components/Button/ButtonCom';
import SelectWithPagination from '../../../../components/SelectWithPagination';

import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';

const NotificationType = ({ onNext, onBack }) => {
  const [radioValue, setRadioValue] = useState('general');

  const [form] = Form.useForm();

  const { id } = useParams();

  const addEditNotification = async (value) => {
    localStorage.setItem('notification-step-1', JSON.stringify(value));
    onNext();
  };

  const loadOptionsOfAlbum = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.ALL_ALBUMS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.albumName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const loadOptionsOfSong = async (search, prevOptions, { skip }) => {
    const res = await SECTION.GET_SONG(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.songTitle,
        media_item: item?.album?.media_item,
        songTitle: item?.songTitle,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const onChange = (e) => {
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    const getNotificationEditData = async () => {
      try {
        const res = await NOTIFICATION.GET_NOTIFICATION_BY_ID(id);
        if (res.status === 1 || res.status === '1') {
          if (res?.data) {
            if (res?.data?.media_item) {
              let data = [
                {
                  uid: '-1',
                  name: 'cover image',
                  status: 'done',
                  url: res?.data?.media_item?.image,
                  thubmUrl: res?.data?.media_item?.image,
                },
              ];
              res['data']['coverImage'] = { fileList: data };
            }

            let stepOne = {};
            if (res?.data?.type === 'general') {
              stepOne.type = 'general';
            } else if (res?.data?.type === 'album') {
              stepOne.type = 'album';
              stepOne.albumId = {
                label: res?.data?.album?.albumName,
                value: res?.data?.album?.id,
              };
            } else {
              stepOne.type = 'song';
              stepOne.songId = {
                label: res?.data?.song?.songTitle,
                value: res?.data?.song?.id,
              };
            }
            setRadioValue(stepOne.type);
            form.setFieldsValue({ ...stepOne });
            let stepTwo = {
              title: res?.data?.title,
              text: res?.data?.text,
              coverImage: res?.data?.coverImage,
              platformAndroid: res?.data?.platformAndroid,
              platformIPhone: res?.data?.platformIPhone,
              mediaItemId: res?.data?.media_item?.id,
            };

            localStorage.setItem(
              'notification-step-1',
              JSON.stringify(stepOne)
            );
            localStorage.setItem(
              'notification-step-2',
              JSON.stringify(stepTwo)
            );
            localStorage.setItem(
              'notification-step-3',
              JSON.stringify({
                publish_date: res?.data?.publish_date,
                isPublished: res?.data?.isPublished,
              })
            );
          }
        }
      } catch (err) {
        toast.error(err.message || 'Something Went Wrong!');
      }
    };
    const data = JSON.parse(localStorage.getItem('notification-step-1')) || '';

    if (id && data === '') {
      getNotificationEditData();
    }
  }, [form, id]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('notification-step-1')) || '';
    const getSingleNotification = async () => {
      setRadioValue(data.type);
      form.setFieldsValue({ ...data });
    };
    if (data) {
      getSingleNotification();
    }
  }, [form, id]);

  return (
    <>
      <InsideHeader className='add-edit-form' style={{ width: '650px' }}>
        <h1 className='font-size-28'>Select Notification Type</h1>
        <Form
          form={form}
          labelWrap={true}
          name='control-hooks'
          onFinish={addEditNotification}
        >
          <Form.Item
            name='type'
            label='Select Notification Type'
            rules={[{ required: true, message: 'Please select type' }]}
          >
            <Radio.Group onChange={onChange}>
              <Radio value={'general'}>General</Radio>
              <Radio value={'album'}>Album</Radio>
              <Radio value={'song'}>Audio</Radio>
              <Radio value={'video'}>Video</Radio>
            </Radio.Group>
          </Form.Item>

          {radioValue === 'album' && (
            <Form.Item
              name='albumId'
              label='Select Album'
              rules={[{ required: true, message: 'Please select album' }]}
            >
              <SelectWithPagination
                placeholder='Select Album'
                loadOptions={loadOptionsOfAlbum}
                additional={{
                  skip: 0,
                }}
                isClearable
              />
            </Form.Item>
          )}

          {radioValue === 'song' && (
            <Form.Item
              name='songId'
              label='Select Songs'
              rules={[{ required: true, message: 'Please select song' }]}
            >
              <SelectWithPagination
                placeholder='Select Song'
                loadOptions={loadOptionsOfSong}
                additional={{
                  skip: 0,
                }}
                isClearable
              />
            </Form.Item>
          )}

          {radioValue === 'video' && (
            <Form.Item
              name='video_url'
              label='Video URL'
              rules={[{ required: true, message: 'video Url Required' }]}
            >
              <Input
                placeholder='https://videourl.com'
                className='input-control'
                suffix={
                  <LinkOutlined
                    style={{
                      fontSize: 16,
                      color: '#1890ff',
                    }}
                  />
                }
                allowClear
              ></Input>
            </Form.Item>
          )}

          <div className='form-btn-group'>
            <ButtonCom className='mr-2 width-180' onClick={() => onBack()}>
              Back
            </ButtonCom>
            <ButtonCom htmlType='submit' type='primary' className='width-180'>
              Next
            </ButtonCom>
          </div>
        </Form>
      </InsideHeader>
    </>
  );
};

export default NotificationType;
