import React from 'react';

import { Col, Drawer, Row } from 'antd';

const InfoDrawer = ({ setVisible, visible, infoDeatils }) => {
  return (
    <Drawer
      title='Album Info'
      placement='right'
      onClose={() => {
        setVisible(false);
      }}
      visible={visible}
    >
      <div className='text-center'>
        <img
          src={infoDeatils?.media_item?.image || ' '}
          alt=''
          width='150'
          height='150'
          className='border-radius-5'
        />
      </div>
      <Row gutter={0} className='mt-2 mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Album Name</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>
            {' '}
            {infoDeatils?.albumName || '-'}
          </h2>
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Album Subtitle</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>{infoDeatils?.subtitle || '-'}</h2>
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Release Date</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>
            {new Date(infoDeatils?.releasedate).toLocaleString('en-US', {
              dateStyle: 'medium',
            }) || '-'}
          </h2>
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Downloadable</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>
            {infoDeatils?.isdownloadable ? 'Yes' : 'No'}
          </h2>
        </Col>
      </Row>
      {/* Total Songs */}
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Total Songs</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>
            {infoDeatils?.album_songs?.length}
          </h2>
        </Col>
      </Row>
    </Drawer>
  );
};

export default InfoDrawer;
