import { useEffect, useState } from 'react';

import PaginationCom from '../../../components/PaginationCom';
import InsideHeader from '../../../components/InsideHeader/InsideHeader';
import DeleteModel from '../../../components/DeleteModel';
import ButtonCom from '../../../components/Button/ButtonCom';

import { Row, Col, Table, Checkbox, Input, Tooltip, Form } from 'antd';
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { SONGS } from '../../../service';

import PositionUpdateModel from './PositionUpdateModel';

import { tableSortIconToggle } from '../../../utility/tableSorting';

const SingleAlbumSongs = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [search, setSearch] = useState(null);
  const [songs, setSongs] = useState({});
  const [albumName, setAlbumName] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [multipleDeleteId, setMultipleDeleteId] = useState([]);
  const [isMultipleDelete, setIsMultipleDelete] = useState(false);

  const [updateSongId, setUpdateSongId] = useState(null);
  const [showUpdateModel, setShowUpdateModel] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const fetchSong = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    id = null
  ) => {
    setLoading(true);
    try {
      const res = await SONGS.GET_SELECT_ALBUM_SONG(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        id
      );
      if (res.status === 1 || res.status === '1') {
        setAlbumName(res.data[0]?.album?.albumName);
        const resetData = [];
        res.data.forEach((item, i) => {
          if (item.song) {
            resetData.push({
              ...item.song,
              albumSongId: item.id,
              positionNo: item.positionNo,
            });
          }
        });
        setSongs({ total: res.total, data: resetData });
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await SONGS.REMOVE_ALBUM_SONG(multipleDeleteId);
      if (res.status === 1 || res.status === '1') {
        setMultipleDeleteId([]);
        setIsMultipleDelete(false);
        let page = currentPage;
        if (songs?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchSong(page, pageSize, search, sort, id);
        }
        toast.success('Record deleted successfully !');
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const deleteSong = async () => {
    try {
      const res = await SONGS.REMOVE_SINGLE_ALBUM_SONG(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        let page = currentPage;
        if (songs?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchSong(page, pageSize, search, sort, id);
        }
        toast.success(res?.message);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const UpdateAlbumSongPosition = async (value) => {
    try {
      const res = await SONGS.UPDATE_ALBUM_SONG(updateSongId, value);
      if (res.status === 1 || res.status === '1') {
        setShowUpdateModel(false);
        setUpdateSongId(null);
        fetchSong(currentPage, pageSize, search, sort, id);
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const rowSelection = {
    onChange: (_, selectedRows) => {
      const selectRowsId = selectedRows.map((item) => {
        return { id: item.albumSongId, deletedAt: new Date() };
      });
      setMultipleDeleteId(selectRowsId);
    },
  };

  useEffect(() => {
    fetchSong(currentPage, pageSize, search, sort, id);
  }, [currentPage, id, pageSize, search, sort]);

  const columns = [
    {
      title: 'Song Title',
      dataIndex: 'songTitle',
      key: 'songTitle',
      sortDirections: ['ascend', 'descend'],
      width: 130,
      ellipsis: true,
    },
    {
      title: 'CMS Path',
      dataIndex: 'cmsPath',
      key: 'cmsPath',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 110,
      ellipsis: true,
    },
    {
      title: 'Mother Album',
      dataIndex: 'album',
      key: 'album',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 140,
      ellipsis: true,
      render: (album) => <span>{album?.albumName}</span>,
    },
    {
      title: 'Song Type',
      dataIndex: 'songType',
      key: 'songType',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 90,
    },
    {
      title: (column) =>
        tableSortIconToggle('Position No', 'positionNo', column),
      dataIndex: 'positionNo',
      key: 'positionNo',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songs?.data?.length > 1 ? true : false,
      width: 90,
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 100,
    },
    {
      title: 'Downloadable',
      dataIndex: 'isdownloadable',
      key: 'isdownloadable',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      render: (_, record) => (
        <Checkbox disabled checked={record?.isdownloadable}></Checkbox>
      ),
      width: 130,
    },
    {
      title: 'Bits',
      align: 'center',
      width: 100,
      render: (_, record) => {
        return (
          <Row gutter={10}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='32 Bits File'>
                <Checkbox
                  disabled
                  checked={record?.media_sound?.bitFile32}
                ></Checkbox>
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='64 Bits File'>
                <Checkbox
                  disabled
                  checked={record?.media_sound?.bitFile64}
                ></Checkbox>
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='128 Bits File'>
                <Checkbox
                  disabled
                  checked={record?.media_sound?.bitFile128}
                ></Checkbox>
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
              <Tooltip title='256 Bits File'>
                <Checkbox
                  disabled
                  checked={record?.media_sound?.bitFile256}
                ></Checkbox>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 120,
      render: (_, record) => {
        return (
          <>
            <Tooltip title='Edit Song'>
              <EditOutlined
                className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                onClick={() => {
                  setUpdateSongId(record?.albumSongId);
                  form.setFieldsValue({ positionNo: record?.positionNo });
                  setShowUpdateModel(true);
                }}
              />
            </Tooltip>
            <Tooltip title='Delete Song'>
              <DeleteOutlined
                onClick={() => {
                  setDeleteId(record.albumSongId);
                  setIsDeleteModalVisible(true);
                }}
                className='font-size-20 delete-btn-hover cursor-pointer mx-1'
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <InsideHeader className='mb-3 pb-1'>
        <Row gutter={20} className='align-items-center'>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={11}
            xl={14}
            className='gutter-row mb-1'
          >
            <Input
              placeholder='Start Typing Search Songs'
              suffix={
                <Tooltip title='Search Now'>
                  <SearchOutlined
                    style={{ color: '#8f9bb3' }}
                    onClick={handleSearch}
                  />
                </Tooltip>
              }
              onChange={(e) => handleChangeOfSearch(e)}
              onKeyPress={(e) => {
                if (e?.key === 'Enter' && e.target.value) {
                  handleSearch();
                }
              }}
              className='input-control'
              value={tempSearch}
              allowClear
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={13}
            xl={10}
            className='gutter-row mb-1'
          >
            <div className='d-flex flex-wrap align-items-center justify-content-md-left justify-content-end'>
              <ButtonCom
                type='primary'
                className='width-180'
                onClick={() => navigate(`/albums/reorder/${id}`)}
              >
                Reorder Song
              </ButtonCom>
            </div>
          </Col>
        </Row>
      </InsideHeader>

      <div className='d-flex align-items-center'>
        <h1 className='mb-0 font-size-28 mr-2'>{albumName}</h1>
      </div>

      <div className='fix-table box-shadow-3 mt-3 border-radius-5'>
        <Table
          rowSelection={{
            type: <Checkbox />,
            ...rowSelection,
            columnWidth: 60,
          }}
          dataSource={songs?.data}
          columns={columns}
          className='border-radius-5'
          pagination={false}
          loading={loading}
          rowKey='id'
          onChange={onChange}
        />

        {songs?.total > 10 && (
          <div className='text-center bg-white px-3 pt-3 width-1120 '>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={songs?.total}
            />
          </div>
        )}

        <div className='text-center bg-white width-1120 py-3'>
          <ButtonCom
            className='width-180 mr-2'
            onClick={() => navigate('/albums')}
          >
            Back
          </ButtonCom>
          {multipleDeleteId.length > 0 && (
            <ButtonCom type='primary' onClick={() => setIsMultipleDelete(true)}>
              Delete Selected Songs
            </ButtonCom>
          )}
        </div>
      </div>

      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteSong}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />

      <DeleteModel
        isDeleteModalVisible={isMultipleDelete}
        deleteModel={handleMultipleDelete}
        setIsDeleteModalVisible={setIsMultipleDelete}
      />

      <PositionUpdateModel
        form={form}
        showUpdateModel={showUpdateModel}
        setShowUpdateModel={setShowUpdateModel}
        UpdateAlbumSongPosition={UpdateAlbumSongPosition}
      />
    </>
  );
};

export default SingleAlbumSongs;
