import { Row, Col, Drawer } from 'antd';

const DrawerForm = ({ visible, setVisible, infoDeatils, setInfoDeatils }) => {
  return (
    <Drawer
      title='Songs Info'
      placement='right'
      onClose={() => {
        setVisible(false);
        setInfoDeatils({});
      }}
      visible={visible}
    >
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Song Title</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>{infoDeatils?.songTitle}</h2>
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>CMS Path</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>{infoDeatils?.cmsPath}</h2>
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Mother Album</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>{infoDeatils?.album?.albumName}</h2>
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Song Type</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>{infoDeatils?.songType}</h2>
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Duration</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>{infoDeatils?.duration}</h2>
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Album Name</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          {infoDeatils?.album_songs?.map((item, i) => {
            return (
              <h2 key={i} className='font-size-15 mb-0'>
                {item?.album?.albumName}
              </h2>
            );
          })}
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Lyrics</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>{infoDeatils?.lyrics}</h2>
        </Col>
      </Row>
      {infoDeatils?.song_artists?.length > 0 && (
        <Row gutter={0} className='mb-1'>
          <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Artists</h2>
          </Col>
          <Col xs={24} sm={18} md={18} lg={16} xl={16} className='gutter-row'>
            {infoDeatils?.song_artists?.map((item, i) => {
              return (
                <Row gutter={0} className='mb-1' key={i}>
                  <Col
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    className='gutter-row'
                  >
                    <h2 className='font-size-15 font-weight-600 mb-0'>
                      {item?.name}
                    </h2>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className='gutter-row text-center'
                  >
                    -
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className='gutter-row'
                  >
                    {item.artist_added_roles.map((role, index) => {
                      return (
                        <h2 key={index} className='font-size-15 mb-0'>
                          {role?.name}
                        </h2>
                      );
                    })}
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      )}
    </Drawer>
  );
};

export default DrawerForm;
