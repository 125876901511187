import React, { useEffect, useState } from 'react';

import { Input, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Section from '../../components/Section/Section';
import InsideHeader from '../../components/InsideHeader/InsideHeader';
import EmptyScreen from '../../components/EmptyScreen';
import SelectDropDown from '../../components/Select';
import ButtonCom from '../../components/Button/ButtonCom';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { SECTION } from '../../service/index';

const Sections = ({ permissions }) => {
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [subTabs, setSubTabs] = useState([]);

  const navigate = useNavigate();

  const fetchSections = async () => {
    try {
      const res = await SECTION.TAB();
      if (res.status === 1 || res.status === '1') {
        const data = res?.data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setTabs(data);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const fetchSubTabs = async (id) => {
    try {
      const res = await SECTION.SINGLE_TAB(id);
      if (res.status === 1 || res.status === '1') {
        setSubTabs(res?.data?.sub_tabs);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong !');
    }
  };

  const handleSearch = () => {
    if (searchValue) {
      const filter = subTabs.map((map) => {
        return {
          ...map,
          sections: map.sections.filter((item) =>
            item.name.toLowerCase().includes(searchValue.toLowerCase())
          ),
        };
      });
      setSubTabs(filter);
    }
  };

  const handleChangeOfSearch = (e) => {
    if (!e.target.value) {
      fetchSubTabs(activeTab);
    }
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    if (activeTab) {
      fetchSubTabs(activeTab?.value);
    }
  }, [activeTab]);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(tabs[0]);
    }
  }, [activeTab, tabs]);

  return (
    <>
      <InsideHeader className='mb-3 pb-1'>
        <Row gutter={20} className='align-items-center'>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            className='gutter-row mb-1'
          >
            <Input
              type='search'
              placeholder='Start Typing Search Section'
              className='input-control'
              value={searchValue}
              allowClear
              onChange={(e) => handleChangeOfSearch(e)}
              suffix={
                <SearchOutlined
                  style={{ color: '#8f9bb3' }}
                  onClick={handleSearch}
                />
              }
              onKeyPress={(e) => {
                if (e?.key === 'Enter' && e.target.value) {
                  handleSearch();
                }
              }}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            className='gutter-row mb-1'
          >
            <div className='d-flex flex-wrap align-items-center  justify-content-md-left justify-content-end'>
              {(permissions?.isCreate ||
                permissions?.isCreate === undefined) && (
                <ButtonCom
                  type='primary'
                  onClick={() => {
                    localStorage.removeItem('subTabId');
                    localStorage.removeItem('tabId');
                    localStorage.removeItem('section-step-1');
                    navigate('/sections/add');
                  }}
                >
                  Create New Section
                </ButtonCom>
              )}
            </div>
          </Col>
        </Row>
      </InsideHeader>

      <InsideHeader>
        <Row gutter={20} className='align-items-center'>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className='gutter-row'>
            <SelectDropDown
              placeholder='Select Tab'
              options={tabs}
              onChange={(e) => setActiveTab(e)}
              value={activeTab}
            />
          </Col>
        </Row>
      </InsideHeader>

      {subTabs && subTabs.length > 0 && (
        <div>
          <Section
            id={activeTab?.value}
            fetchSubTabs={fetchSubTabs}
            subTabs={subTabs}
            permissions={permissions}
          />
        </div>
      )}

      {subTabs.length <= 0 && (
        <div className='section-empty-screen'>
          <EmptyScreen />
        </div>
      )}
    </>
  );
};

export default Sections;
