import React from 'react';

import moment from 'moment';

import { Col, Drawer, Row } from 'antd';

const InfoDrawer = ({
  setInfoSliderVisible,
  infoSliderVisible,
  infoSliderContent,
}) => {
  return (
    <Drawer
      title='Notification Info'
      placement='right'
      onClose={() => setInfoSliderVisible(false)}
      visible={infoSliderVisible}
    >
      <div className='text-center'>
        <img
          src={infoSliderContent?.media_item?.image || ' '}
          alt=''
          width='150px'
          height='150px'
          className='border-radius-5'
        />
      </div>
      <Row gutter={0} className='mt-2 mb-1'>
        <Col xs={24} sm={6} md={6} lg={10} xl={10} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>
            Notification Title
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={12} xl={12} className='gutter-row'>
          <h2 className='font-size-15 mb-0'> {infoSliderContent?.title}</h2>
        </Col>
      </Row>
      <Row gutter={0} className='mt-2 mb-1'>
        <Col xs={24} sm={6} md={6} lg={10} xl={10} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>
            Notification Text
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={12} xl={12} className='gutter-row'>
          <h2 className='font-size-15 mb-0'> {infoSliderContent?.text}</h2>
        </Col>
      </Row>
      <Row gutter={0} className='mt-2 mb-1'>
        <Col xs={24} sm={6} md={6} lg={10} xl={10} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Type</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={12} xl={12} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>
            {infoSliderContent?.type?.charAt(0).toUpperCase() +
              infoSliderContent?.type?.slice(1)}
          </h2>
        </Col>
      </Row>
      {infoSliderContent?.video_url && (
        <Row gutter={0} className='mt-2 mb-1'>
          <Col xs={24} sm={6} md={6} lg={10} xl={10} className='gutter-row'>
            <h2 className='font-size-15 font-weight-600 mb-0'>Video Url</h2>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
            -
          </Col>
          <Col xs={22} sm={16} md={16} lg={12} xl={12} className='gutter-row'>
            <h2 className='font-size-15 mb-0'>
              {infoSliderContent?.video_url}
            </h2>
          </Col>
        </Row>
      )}
      <Row gutter={0} className='mt-2 mb-1'>
        <Col xs={24} sm={6} md={6} lg={10} xl={10} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>
            Platform Android
          </h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={12} xl={12} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>
            {infoSliderContent?.platformAndroid ? 'Yes' : 'No'}
          </h2>
        </Col>
      </Row>
      <Row gutter={0} className='mt-2 mb-1'>
        <Col xs={24} sm={6} md={6} lg={10} xl={10} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Platform IOS</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={12} xl={12} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>
            {infoSliderContent?.platformIPhone ? 'Yes' : 'No'}
          </h2>
        </Col>
      </Row>
      <Row gutter={0} className='mt-2 mb-1'>
        <Col xs={24} sm={6} md={6} lg={10} xl={10} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Scheduled</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={12} xl={12} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>
            {infoSliderContent?.isPublished === false ? 'Yes' : 'No'}
          </h2>
        </Col>
      </Row>
      <Row gutter={0} className='mt-2 mb-1'>
        <Col xs={24} sm={6} md={6} lg={10} xl={10} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Publish Date</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={12} xl={12} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>
            {moment(infoSliderContent?.publish_date).format(
              'ddd, MMM Do YYYY, h:mm:ss a'
            )}
          </h2>
        </Col>
      </Row>
    </Drawer>
  );
};

export default InfoDrawer;
