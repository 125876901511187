import React, { useEffect, useState } from 'react';

import { Avatar, Menu, Dropdown } from 'antd';

import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import JalsoLogoImage from '../../assets/image/jalso-logo.svg';

import { useDispatch, useSelector } from 'react-redux';

import { LOGOUT } from '../../redux/features/userSlice';

import BellIcon from '../Icon/BellIcon';
import PersonIcon from '../Icon/PersonIcon';
import LogOutIcon from '../Icon/LogOutIcon';
import LogOutModal from '../Modal/LogOutModal';

const Header = () => {
  const [userDetails, setUserDetails] = useState({
    fullname: '',
    username: '',
  });
  const [visible, setVisible] = useState(false);

  const { userValue } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogOut = async () => {
    localStorage.removeItem('user');
    localStorage.removeItem('jalso-token');
    dispatch(LOGOUT());
    toast.success('Logout Successfully !');
    navigate('/login');
  };

  const routingPath = (path) => {
    if (path) {
      if (path === 'app_users') {
        return '/app-users';
      } else if (path === 'admin_users') {
        return '/admin-users';
      } else {
        return `/${path}`;
      }
    } else {
      return '/dashboard';
    }
  };

  const getFirstLetters = (param) => {
    const firstLetters = param
      .split(' ')
      .map((word) => word[0])
      .join('');
    return firstLetters;
  };

  useEffect(() => {
    if (userValue && userValue !== undefined) {
      setUserDetails(userValue);
    }
  }, [userValue]);

  return (
    <>
      <div className='header d-flex align-items-center justify-content-between px-2'>
        <div className='d-flex align-items-center'>
          <Link to={routingPath(userValue?.role?.permissions[0]?.page?.value)}>
            <img src={JalsoLogoImage} alt='logo' width='90px' height='40px' />
          </Link>
        </div>
        <div className='d-flex align-items-center'>
          <div className='d-sm-none'>
            <BellIcon />
          </div>
          <div className='text-gray vertical-line-right-header-style'></div>
          <div>
            <Dropdown
              placement='bottom'
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key='0' onClick={() => navigate('profile')}>
                    <div className='d-flex align-items-center'>
                      <PersonIcon />
                      <h4 className='font-size-15 mb-0 ml-2'>Profile</h4>
                    </div>
                  </Menu.Item>
                  <Menu.Item key='1' onClick={() => setVisible(true)}>
                    <div className='d-flex align-items-center'>
                      <LogOutIcon />
                      <h4 className='font-size-15 mb-0 ml-2'>Logout</h4>
                    </div>
                  </Menu.Item>
                </Menu>
              }
            >
              <div
                className='d-flex cursor-pointer'
                onClick={(e) => e.preventDefault()}
              >
                <Avatar
                  className='border-gray-1'
                  size={40}
                  style={{
                    color: '#000',
                    backgroundColor: '#fff',
                    marginRight: '9px',
                  }}
                >
                  {getFirstLetters(userDetails.fullname.toUpperCase())}
                </Avatar>
                <div>
                  <h4 className='mb-0 font-size-15'>{userDetails.fullname}</h4>
                  <p className='mb-0 font-size-13 line-height-6'>
                    {userDetails.username}
                  </p>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* LogOut Modal */}
      <LogOutModal
        visible={visible}
        setVisible={setVisible}
        handleLogOut={handleLogOut}
      />
    </>
  );
};

export default Header;
