import { useState, useEffect } from 'react';

import DeleteModel from '../../components/DeleteModel';
import PaginationCom from '../../components/PaginationCom';
import InsideHeader from '../../components/InsideHeader/InsideHeader';
import TableImage from '../../components/Image/TableImage';
import SelectDropDown from '../../components/Select';

import { Table, Input, Modal, Tooltip, Row, Col } from 'antd';
import {
  DeleteOutlined,
  SearchOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';

import { toast } from 'react-toastify';

import { APP_USERS } from '../../service/index';

import { tableSortIconToggle } from '../../utility/tableSorting';

const AppUsers = ({ permissions }) => {
  const [appUsers, setAppUsers] = useState({});
  const [tableLoading, setTableLoading] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [blockUserModalVisible, setBlockUserModalVisible] = useState(false);
  const [blockInputValue, setBlockInputValue] = useState('');
  const [blockModalData, setBlockModalData] = useState({
    title: 'Block',
    id: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  const [tableDataLength, setTableDataLength] = useState(0);

  const genderOption = [
    { label: 'All', value: 'all' },
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' },
  ];

  const fetchAppUsers = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    gender = null
  ) => {
    setTableLoading(true);
    try {
      const res = await APP_USERS.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        gender
      );
      if (res.status === 1 || res.status === '1') {
        setAppUsers(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || 'Something went wrong!');
    } finally {
      setTableLoading(false);
    }
  };

  const deleteAppUser = async () => {
    try {
      const res = await APP_USERS.DELETE(deleteId);

      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        toast.success(res?.message);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (appUsers?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchAppUsers(page, pageSize, search, sort, filterValue?.value);
        }
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const blockUnblockUser = async () => {
    const payload = {
      userBlock: !blockModalData.userBlock,
      blockReason: blockInputValue,
    };
    try {
      const res = await APP_USERS.BLOCK(blockModalData?.id, payload);
      if (res.status === 1) {
        toast.success(`user ${blockModalData?.title} successfully`);
        fetchAppUsers(currentPage, pageSize, search, sort, filterValue?.value);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    } finally {
      setBlockInputValue(null);
      setBlockUserModalVisible(false);
    }
  };

  const searchAppUsers = () => {
    setSearch(tempSearch);
    setCurrentPage(1);
  };

  const searchUsersBySelect = (e) => {
    setCurrentPage(1);
    if (e) {
      setFilterValue(e);
      if (e?.value === 'all') {
        e = null;
        setFilterValue(null);
      }
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  useEffect(() => {
    fetchAppUsers(currentPage, pageSize, search, sort, filterValue?.value);
  }, [currentPage, filterValue?.value, pageSize, search, sort]);

  const columns = [
    {
      title: 'Profile',
      dataIndex: 'image',
      key: 'image',
      align: 'center',
      render: (profile) => <TableImage image={profile} />,
      width: 130,
    },
    {
      title: (column) => tableSortIconToggle('Full Name', 'fullname', column),
      dataIndex: 'fullname',
      key: 'fullname',
      align: 'center',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? value.charAt(0).toUpperCase() + value.slice(1) : '-',
    },
    {
      title: (column) => tableSortIconToggle('Email Id', 'email', column),
      key: 'email',
      dataIndex: 'email',
      align: 'center',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? value : '-'),
    },
    {
      title: (column) =>
        tableSortIconToggle('Mobile No', 'phoneNumber', column),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? value : '-'),
      width: 130,
    },
    {
      title: (column) =>
        tableSortIconToggle('Country Code', 'countryCode', column),
      dataIndex: 'countryCode',
      key: 'countryCode',
      align: 'center',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? value : '-'),
      width: 170,
    },
    {
      title: (column) => tableSortIconToggle('Birth Date', 'dob', column),
      key: 'dob',
      dataIndex: 'dob',
      align: 'center',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? value : '-'),
      width: 140,
    },
    {
      title: (column) => tableSortIconToggle('Gender', 'gender', column),
      key: 'gender',
      dataIndex: 'gender',
      align: 'center',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? value.charAt(0).toUpperCase() + value.slice(1) : '-',
      width: 140,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 140,
      render: (item, record) => {
        return (
          <div>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip
                title={record?.userBlock ? 'Unblock User' : ' Block User'}
              >
                {record?.userBlock ? (
                  <UnlockOutlined
                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                    onClick={() => {
                      setBlockModalData({
                        title: 'Unblock',
                        id: record?.id,
                        userBlock: record?.userBlock || false,
                        blockReason: record?.blockReason,
                      });
                      setBlockUserModalVisible(true);
                    }}
                  />
                ) : (
                  <LockOutlined
                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                    onClick={() => {
                      setBlockModalData({
                        title: 'Block',
                        id: record?.id,
                        userBlock: record?.userBlock || false,
                        blockReason: record?.blockReason,
                      });
                      setBlockUserModalVisible(true);
                    }}
                  />
                )}
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete User'>
                <DeleteOutlined
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  if (permissions?.isUpdate === false && permissions?.isDelete === false) {
    delete columns[7];
  }
  return (
    <>
      <InsideHeader className='px-2 pt-2 pb-1'>
        <Row gutter={20} className='align-items-center'>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={11}
            xl={14}
            className='gutter-row mb-1'
          >
            <Input
              className='input-control'
              placeholder='Start typing to Search User'
              onChange={(e) => {
                if (!e?.target?.value) {
                  setSearch(null);
                  setTempSearch(null);
                } else {
                  setTempSearch(e?.target?.value);
                }
              }}
              onKeyPress={(e) => {
                if (e?.key === 'Enter' && e.target.value) {
                  searchAppUsers();
                }
              }}
              suffix={<SearchOutlined onClick={searchAppUsers} />}
              allowClear
              value={tempSearch}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={13}
            xl={10}
            className='gutter-row mb-1'
          >
            <div className='d-flex flex-wrap align-items-center justify-content-md-left justify-content-end'>
              <SelectDropDown
                placeholder='Select Gender'
                options={genderOption}
                onChange={(e) => searchUsersBySelect(e)}
                value={filterValue || { label: 'All', value: 'all' }}
                className='width-250'
              />
            </div>
          </Col>
        </Row>
      </InsideHeader>

      <div className='fix-table mt-3 border-radius-5 box-shadow-3'>
        <Table
          columns={columns}
          dataSource={appUsers?.data}
          pagination={false}
          rowKey='id'
          loading={tableLoading}
          onChange={onChange}
        />

        {appUsers?.total > 10 && (
          <div className='text-center bg-white p-3 width-1120'>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={appUsers?.total}
            />
          </div>
        )}
      </div>

      <Modal
        centered='true'
        okText={blockModalData?.title}
        cancelText='cancel'
        style={{ textAlign: 'center' }}
        title={blockModalData?.title}
        visible={blockUserModalVisible}
        onOk={blockUnblockUser}
        onCancel={() => setBlockUserModalVisible(false)}
      >
        <h3>Why want to {blockModalData?.title}</h3>
        {blockModalData?.blockReason && (
          <p>
            {blockModalData?.userBlock ? 'Block' : 'Unblock'} Reason :{' '}
            {blockModalData?.blockReason}
          </p>
        )}
        <Input
          placeholder={`Why want to ${blockModalData?.title}`}
          className='input-control'
          value={blockInputValue}
          onChange={(e) => setBlockInputValue(e?.target?.value)}
        ></Input>
      </Modal>

      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteAppUser}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </>
  );
};

export default AppUsers;
