import React, { useEffect, useState } from 'react';

import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';

import { Layout } from 'antd';

import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import { LOGOUT } from '../../redux/features/userSlice';

const { Content } = Layout;

const AdminLayout = () => {
  const [isShowSidebar, setIsShowSidebar] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const token = localStorage.getItem('jalso-token');
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (!user || !token) {
      localStorage.clear();
      dispatch(LOGOUT());
      navigate('/login');
      toast.error('User is not authenticated !');
    }
  }, [dispatch, location.pathname, navigate, token, user]);

  useEffect(() => {
    setTimeout(() => {
      localStorage.clear();
      dispatch(LOGOUT());
      navigate('/login');
      toast.error('User is not authenticated !');
    }, 1000 * 60 * 60 * 24);
  }, [dispatch, navigate]);

  return (
    <>
      {token ? (
        <Layout style={{ minHeight: '100vh' }}>
          <Header />
          <Layout>
            <Sidebar setIsShowSidebar={setIsShowSidebar} />
            <Content
              style={{
                marginLeft: isShowSidebar ? '80px' : '200px',
                transition: 'all 0.4s',
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Navigate to='/login' />
      )}
    </>
  );
};

export default AdminLayout;
