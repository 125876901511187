import React from 'react';

import { Col } from 'antd';

import Chart from 'react-google-charts';

import EmptyScreen from '../EmptyScreen';

const UserDownload = ({ optionForUserDownload, data }) => {
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={14} xl={14} className=' mt-3 gutter-row'>
        <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
          <h2 className='font-weight-700 mb-3'>Users Download</h2>
          <div
            className={
              data?.length > 0
                ? 'user-download'
                : 'user-download d-flex align-items-center justify-content-center'
            }
          >
            {data.length > 0 ? (
              <Chart
                chartType='Line'
                width='100%'
                height='400px'
                data={data}
                options={optionForUserDownload}
              />
            ) : (
              <EmptyScreen />
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

export default UserDownload;
