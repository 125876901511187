import React from 'react';
import { Row, Col } from 'antd';

const ThreeBox = ({
  title,
  boxOneTitle,
  boxOneNum,
  boxOnePer,
  boxTwoTitle,
  boxTwoNum,
  boxTwoPer,
  boxThreeTitle,
  boxThreeNum,
  boxThreePer,
}) => {
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={10} xl={10} className=' mt-3 gutter-row'>
        <div className='bg-white border-radius-10 p-2 '>
          <h2 className='font-weight-700 mb-0'>{title}</h2>
          <Row justify='space-between' gutter={25}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className=' mt-2 gutter-row'
            >
              <div className='bg-light-gray2  border-radius-10 p-2 '>
                <h3 className='font-weight-700 mb-0'>{boxOneTitle}</h3>
                <Row align='bottom'>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className=' mt-2 gutter-row'
                  >
                    <h1 className='pl-1 font-size-50 mb-0'>{boxOneNum}</h1>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className=' mt-2 gutter-row'
                  >
                    <div className='d-flex align-items-center pl-3'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 24 24'
                        className='eva eva-arrow-upward-outline'
                        fill='currentColor'
                      >
                        <g data-name='Layer 2'>
                          <g data-name='arrow-upward'>
                            <rect
                              width='24'
                              height='24'
                              transform='rotate(180 12 12)'
                              opacity='0'
                            ></rect>
                            <path d='M5.23 10.64a1 1 0 0 0 1.41.13L11 7.14V19a1 1 0 0 0 2 0V7.14l4.36 3.63a1 1 0 1 0 1.28-1.54l-6-5-.15-.09-.13-.07a1 1 0 0 0-.72 0l-.13.07-.15.09-6 5a1 1 0 0 0-.13 1.41z'></path>
                          </g>
                        </g>
                      </svg>
                      <h3 className='pl-1 mb-0'> {boxOnePer}</h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row justify='space-between' gutter={25}>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className=' mt-2 gutter-row'
            >
              <div className='bg-light-gray2  border-radius-10 p-2 '>
                <h3 className='font-weight-700 mb-0'>{boxTwoTitle}</h3>
                <Row align='bottom' className='pl-1'>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='gutter-row'
                  >
                    <h1 className='font-size-50 mb-0'>{boxTwoNum}</h1>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='gutter-row'
                  >
                    <div className='d-flex align-items-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 24 24'
                        className='eva eva-arrow-upward-outline'
                        fill='currentColor'
                      >
                        <g data-name='Layer 2'>
                          <g data-name='arrow-upward'>
                            <rect
                              width='24'
                              height='24'
                              transform='rotate(180 12 12)'
                              opacity='0'
                            ></rect>
                            <path d='M5.23 10.64a1 1 0 0 0 1.41.13L11 7.14V19a1 1 0 0 0 2 0V7.14l4.36 3.63a1 1 0 1 0 1.28-1.54l-6-5-.15-.09-.13-.07a1 1 0 0 0-.72 0l-.13.07-.15.09-6 5a1 1 0 0 0-.13 1.41z'></path>
                          </g>
                        </g>
                      </svg>
                      <h3 className='pl-1 mb-0'> {boxTwoPer}</h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className=' mt-2 gutter-row'
            >
              <div className='bg-light-gray2  border-radius-10 p-2 '>
                <h3 className='font-weight-700 mb-0'>{boxThreeTitle}</h3>
                <Row align='bottom' className='pl-1'>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='gutter-row'
                  >
                    <h1 className='font-size-50 mb-0'>{boxThreeNum}</h1>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='gutter-row'
                  >
                    <div className='d-flex align-items-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 24 24'
                        className='eva eva-arrow-upward-outline'
                        fill='currentColor'
                      >
                        <g data-name='Layer 2'>
                          <g data-name='arrow-upward'>
                            <rect
                              width='24'
                              height='24'
                              transform='rotate(180 12 12)'
                              opacity='0'
                            ></rect>
                            <path d='M5.23 10.64a1 1 0 0 0 1.41.13L11 7.14V19a1 1 0 0 0 2 0V7.14l4.36 3.63a1 1 0 1 0 1.28-1.54l-6-5-.15-.09-.13-.07a1 1 0 0 0-.72 0l-.13.07-.15.09-6 5a1 1 0 0 0-.13 1.41z'></path>
                          </g>
                        </g>
                      </svg>
                      <h3 className='pl-1 mb-0'>{boxThreePer}</h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default ThreeBox;
