import React, { useState, useEffect } from 'react';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import DeleteModel from '../../components/DeleteModel';
import ButtonCom from '../../components/Button/ButtonCom';

import { Table, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { ARTIST_ROLE } from '../../service/index';

import { tableSortIconToggle } from '../../utility/tableSorting';

const ArtistRole = ({ permissions }) => {
  const [roles, setRoles] = useState([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();

  const fetchRoles = async () => {
    setTableLoading(true);
    try {
      const res = await ARTIST_ROLE.GET();
      if (res.status === 1 || res.status === '1') {
        setRoles(res);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    } finally {
      setTableLoading(false);
    }
  };

  const deleteRole = async () => {
    try {
      const res = await ARTIST_ROLE.DELETE(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        fetchRoles();
        toast.success(res?.message);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const columns = [
    {
      title: (column) =>
        tableSortIconToggle('Roles title', 'rolesTitle', column),
      dataIndex: 'name',
      key: 'rolesTitle',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      render: (item, record) => {
        return (
          <>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Roles'>
                <EditOutlined
                  onClick={() => navigate(`/artists/role/edit/${record?.id}`)}
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}

            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Roles'>
                <DeleteOutlined
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </>
        );
      },
      width: '250px',
    },
  ];

  if (permissions?.isUpdate === false && permissions?.isDelete === false) {
    delete columns[1];
  }

  return (
    <>
      {(permissions?.isCreate || permissions?.isCreate === undefined) && (
        <InsideHeader>
          <ButtonCom
            type='primary'
            className='width-180'
            onClick={() => navigate('/artists/role/add')}
          >
            Add New Role
          </ButtonCom>
        </InsideHeader>
      )}
      <div className='fix-table mt-3 border-radius-5 box-shadow-3'>
        <Table
          className='small-table'
          columns={columns}
          dataSource={roles?.data}
          rowKey='id'
          pagination={false}
          loading={tableLoading}
        />
        <div className='text-center bg-white py-3 width-1120'>
          <ButtonCom className='width-180' onClick={() => navigate('/artists')}>
            Back
          </ButtonCom>
        </div>
      </div>

      {/* Delete Model */}
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteRole}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </>
  );
};

export default ArtistRole;
