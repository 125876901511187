import { useEffect, useState } from 'react';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import PaginationCom from '../../components/PaginationCom';
import DeleteModel from '../../components/DeleteModel';
import TableImage from '../../components/Image/TableImage';
import SelectWithPagination from '../../components/SelectWithPagination';
import ButtonCom from '../../components/Button/ButtonCom';

import { Table, Checkbox, Input, DatePicker, Row, Col, Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  FilterFilled,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { ALBUMS } from '../../service/index';

import { tableSortIconToggle } from '../../utility/tableSorting';

import InfoDrawer from './InfoDrawer/InfoDrawer';

const { RangePicker } = DatePicker;

const Albums = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [albumsData, setAlbumsData] = useState({});
  const [sort, setSort] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueOfRangePicker, setValueOfRangePicker] = useState(null);

  const [selectRow, setSelectRow] = useState([]);
  const [selectRowKey, setSelectRowKey] = useState([]);
  const [isMultipleDelete, setIsMultipleDelete] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [infoDeatils, setInfoDeatils] = useState({});

  const [isShowFilter, setIsShowFilter] = useState(false);

  const [visible, setVisible] = useState(false);

  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [isdownloadable, setIsdownloadable] = useState(null);

  const navigate = useNavigate();

  const getListOfAlbums = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    categoryId = null,
    subcategoryId = null,
    isdownloadable = null,
    startDate = null,
    endDate = null
  ) => {
    setLoading(true);
    try {
      const res = await ALBUMS.GET_ALBUMS(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        categoryId,
        subcategoryId,
        isdownloadable,
        startDate,
        endDate
      );
      if (res.status === 1 || res.status === '1') {
        setAlbumsData(res);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const deleteModel = async () => {
    try {
      const res = await ALBUMS.REMOVE_ALBUMS(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        let page = currentPage;
        if (albumsData?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          getListOfAlbums(
            currentPage,
            pageSize,
            search,
            sort,
            category?.value,
            subCategory?.value,
            isdownloadable
          );
        }
        toast.success(res?.message);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const loadOptionsOfCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.GET_CATEGORY_OF_ALBUMS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.categoryName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const loadOptionsOfSubCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.GET_SUB_CATEGORY_OF_ALBUMS(
      category?.value,
      10,
      skip,
      search
    );
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.subCategoryName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const enterSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const handleChangeOfRangePicker = (e) => {
    setCurrentPage(1);
    setValueOfRangePicker(e);
    if (e) {
      setStartDate(
        new Date(e[0]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
      setEndDate(
        new Date(e[1]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleChangeOfDownloadable = (e) => {
    setCurrentPage(1);
    setIsdownloadable(e.target.checked);
  };

  const clearAllFilter = () => {
    setCurrentPage(1);
    setPageSize(10);
    setSearch(null);
    setTempSearch(null);
    setSort(null);
    setValueOfRangePicker(null);
    setStartDate(null);
    setEndDate(null);
    setIsdownloadable(null);
    setCategory(null);
    setSubCategory(null);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectRowKey,
    onChange: (selectedRowKeys, selectRowRecord) => {
      setSelectRow(selectRowRecord);
      setSelectRowKey(selectedRowKeys);
    },
  };

  const handleMultipleDelete = async () => {
    const payload = selectRow.map((item) => {
      return { id: item.id, deletedAt: new Date() };
    });
    try {
      const res = await ALBUMS.MULTIPLE_DELETE_ALBUMS(payload);
      if (res.status === 1 || res.status === '1') {
        setSelectRow([]);
        setSelectRowKey([]);
        setIsMultipleDelete(false);
        let page = currentPage;
        if (albumsData?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          getListOfAlbums(
            currentPage,
            pageSize,
            search,
            sort,
            category,
            subCategory,
            isdownloadable
          );
        }
        toast.success('Record deleted successfully !');
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong !');
    }
  };

  useEffect(() => {
    getListOfAlbums(
      currentPage,
      pageSize,
      search,
      sort,
      category?.value,
      subCategory?.value,
      isdownloadable,
      startDate,
      endDate
    );
  }, [
    category?.value,
    currentPage,
    endDate,
    isdownloadable,
    pageSize,
    search,
    sort,
    startDate,
    subCategory?.value,
  ]);

  const columns = [
    {
      title: 'Cover Image',
      dataIndex: 'media_item',
      key: 'media_item',
      align: 'left',
      width: 130,
      render: (media_item) => <TableImage image={media_item?.image} />,
    },
    {
      title: (column) =>
        tableSortIconToggle('Albums Title', 'albumName', column),
      dataIndex: 'albumName',
      key: 'albumName',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumsData?.data?.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggle('Subtitle', 'subtitle', column),
      dataIndex: 'subtitle',
      key: 'subtitle',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumsData?.data?.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) =>
        tableSortIconToggle('Downloadable', 'isdownloadable', column),
      dataIndex: 'isdownloadable',
      key: 'isdownloadable',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumsData?.data?.length > 1 ? true : false,
      render: (isdownloadable) => {
        return <Checkbox checked={isdownloadable} disabled />;
      },
      width: 170,
    },
    {
      title: (column) =>
        tableSortIconToggle('Release Date', 'releasedate', column),
      key: 'releasedate',
      dataIndex: 'releasedate',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumsData?.data?.length > 1 ? true : false,
      width: 160,
      render: (releasedate) => {
        return (
          <>
            {new Date(releasedate).toLocaleString('en-US', {
              dateStyle: 'medium',
            })}
          </>
        );
      },
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 200,
      render: (item, record) => {
        return (
          <>
            <Tooltip title='View Album Songs'>
              <PlayCircleOutlined
                className='font-size-20 clone-btn-hover cursor-pointer mx-1'
                onClick={() => {
                  navigate(`/albums/single/${record.id}`);
                }}
              />
            </Tooltip>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Album'>
                <EditOutlined
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                  onClick={() => {
                    localStorage.removeItem('step1');
                    localStorage.removeItem('step2');
                    navigate(`/albums/edit/${record.id}`);
                  }}
                />
              </Tooltip>
            )}
            <Tooltip title='View Album'>
              <InfoCircleOutlined
                className='font-size-20 info-btn-hover cursor-pointer mx-1'
                onClick={() => {
                  setVisible(true);
                  setInfoDeatils(record);
                }}
              />
            </Tooltip>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Album'>
                <DeleteOutlined
                  onClick={() => {
                    setDeleteId(record.id);
                    setIsDeleteModalVisible(true);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <InsideHeader className='mb-3 pb-1'>
        <Row gutter={20} className='align-items-center'>
          <Col xs={24} sm={24} md={24} lg={11} xl={14} className='gutter-row'>
            <Input
              placeholder='Start Typing Search Albums'
              suffix={
                <SearchOutlined
                  style={{ color: '#8f9bb3' }}
                  onClick={enterSearch}
                />
              }
              onChange={(e) => handleChangeOfSearch(e)}
              onKeyPress={(e) => {
                if (e?.key === 'Enter' && e.target.value) {
                  enterSearch();
                }
              }}
              className='input-control mb-1'
              value={tempSearch}
              allowClear
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={13} xl={10} className='gutter-row'>
            <div className='d-flex flex-wrap align-items-center justify-content-md-left justify-content-end'>
              {(permissions?.isCreate ||
                permissions?.isCreate === undefined) && (
                <ButtonCom
                  type='primary'
                  className='mr-1 mb-1'
                  onClick={() => {
                    navigate('/albums/add');
                    localStorage.removeItem('step1');
                    localStorage.removeItem('step2');
                    localStorage.removeItem('file');
                    localStorage.removeItem('category');
                  }}
                >
                  Create New Album
                </ButtonCom>
              )}
              <ButtonCom
                type='primary'
                className='mb-1'
                onClick={() => setIsShowFilter(!isShowFilter)}
              >
                <div className='d-flex align-items-center'>
                  Album Filters
                  <FilterFilled className='ml-1' />
                </div>
              </ButtonCom>
            </div>
          </Col>
        </Row>
      </InsideHeader>

      {isShowFilter && (
        <InsideHeader className='mt-3 pt-2 pb-1'>
          <Row gutter={20} className='align-items-center'>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <RangePicker
                onChange={(e) => handleChangeOfRangePicker(e)}
                value={valueOfRangePicker}
                className='input-control w-100'
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <SelectWithPagination
                placeholder='Select Category'
                loadOptions={loadOptionsOfCategory}
                onChange={(e) => {
                  setCategory(e);
                  setSubCategory(null);
                }}
                additional={{
                  skip: 0,
                }}
                value={category}
                isClearable
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <SelectWithPagination
                key={category?.value}
                placeholder='Select Sub-Category'
                loadOptions={loadOptionsOfSubCategory}
                onChange={(e) => setSubCategory(e)}
                additional={{
                  skip: 0,
                  categoryId: category?.value,
                }}
                value={subCategory}
                isClearable
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <div className='d-flex align-items-center'>
                <h3 className='font-size-16 mb-0 mr-1'>Downloadable</h3>
                <Checkbox
                  className='h-25px'
                  onChange={(e) => handleChangeOfDownloadable(e)}
                  checked={isdownloadable}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <h3 className='clear-all' onClick={clearAllFilter}>
                Clear All
              </h3>
            </Col>
          </Row>
        </InsideHeader>
      )}

      <div className='fix-table mt-3 box-shadow-3 border-radius-5'>
        <Table
          rowSelection={{
            type: <Checkbox />,
            columnWidth: 60,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={albumsData.data}
          pagination={false}
          loading={loading}
          rowKey='id'
          onChange={onChange}
        />
        {selectRow.length > 0 && (
          <div className='text-center bg-white py-3 width-1120'>
            <ButtonCom type='primary' onClick={() => setIsMultipleDelete(true)}>
              Delete Selected Album
            </ButtonCom>
          </div>
        )}

        {albumsData?.total > 10 && (
          <div
            className={
              selectRow.length <= 0
                ? `text-center bg-white p-3 width-1120`
                : `text-center bg-white px-3 pb-3 width-1120`
            }
          >
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={albumsData?.total}
            />
          </div>
        )}
      </div>

      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteModel}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />

      <DeleteModel
        isDeleteModalVisible={isMultipleDelete}
        deleteModel={handleMultipleDelete}
        setIsDeleteModalVisible={setIsMultipleDelete}
      />

      <InfoDrawer
        setVisible={setVisible}
        visible={visible}
        infoDeatils={infoDeatils}
      />
    </>
  );
};

export default Albums;
