import React, { useEffect, useState } from 'react';

import InsideHeader from '../../../components/InsideHeader/InsideHeader';
import PaginationCom from '../../../components/PaginationCom';
import DeleteModel from '../../../components/DeleteModel';
import TableImage from '../../../components/Image/TableImage';
import ButtonCom from '../../../components/Button/ButtonCom';

import { Table, Input, Row, Col, Checkbox, Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { SECTION } from '../../../service/index';

import { tableSortIconToggle } from '../../../utility/tableSorting';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import InfoDrawer from '../../../components/Section/InfoDrawer/InfoDrawer';

const ShowItem = ({ permissions }) => {
  const [itemData, setItemData] = useState({});
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState(null);
  const [tempSearchValue, setTempSearchValue] = useState(null);
  const [sort, setSort] = useState(null);

  const [visible, setVisible] = useState(false);
  const [infoData, setInfoData] = useState(null);

  const [selectRowRecord, setSelectRowRecord] = useState([]);
  const [selectRowKey, setSelectRowKey] = useState([]);

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const getAllItem = async (
    id,
    page = 1,
    pageSize = 10,
    search = null,
    sort = null
  ) => {
    setLoading(true);
    try {
      const res = await SECTION.QUERY_SECTION_ITEM(
        id,
        pageSize,
        (page - 1) * pageSize,
        search,
        sort
      );
      if (res.status === 1 || res.status === '1') {
        setItemData(res);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message || 'Something went wrong !');
    } finally {
      setLoading(false);
    }
  };

  const deleteModel = async () => {
    try {
      const res = await SECTION.REMOVE_SECTION_ITEM(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        let page = currentPage;
        if (itemData?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          getAllItem(id, page, pageSize, searchValue, sort);
        }
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong !');
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ positionNo: -1 });
    }
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectRowKey,
    onChange: (selectedRowKeys, selectRowRecord) => {
      setSelectRowRecord(selectRowRecord);
      setSelectRowKey(selectedRowKeys);
    },
  };

  const handleShowHome = async (value) => {
    const payload = selectRowRecord.map((item) => {
      return {
        id: item.id,
        isShowHome: value,
      };
    });
    try {
      const res = await SECTION.MULTIPLE_PATCH_SECTION_ITEM(payload);
      if (res.status === 1 || res.status === '1') {
        setSelectRowRecord([]);
        setSelectRowKey([]);
        getAllItem(id, currentPage, pageSize, searchValue, sort);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong !');
    }
  };

  const enterSearch = () => {
    setSearchValue(tempSearchValue);
    setCurrentPage(1);
  };

  const handleChangeOfSearch = (e) => {
    if (!e.target?.value) {
      setCurrentPage(1);
      setTempSearchValue(null);
      setSearchValue(null);
    }
    setTempSearchValue(e.target.value);
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAllItem(id, currentPage, pageSize, searchValue, sort);
  }, [currentPage, id, pageSize, searchValue, sort]);

  const columns = [
    {
      title: 'Cover Image',
      dataIndex: 'media_item',
      key: 'media_item',
      align: 'center',
      width: 130,
      render: (media_item) => {
        return <TableImage image={media_item?.image} />;
      },
    },
    {
      title: (column) => tableSortIconToggle('Item Name', 'title', column),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: itemData?.data?.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggle('SubTitle', 'subtitle', column),
      dataIndex: 'subtitle',
      key: 'subtitle',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: itemData?.data?.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) =>
        tableSortIconToggle('Position No', 'positionNo', column),
      dataIndex: 'positionNo',
      key: 'positionNo',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: itemData?.data?.length > 1 ? true : false,
      width: 150,
    },
    {
      title: 'Item Type',
      dataIndex: 'itemType',
      key: 'itemType',
      align: 'center',
      width: 190,
    },
    {
      title: (column) => tableSortIconToggle('Show Home', 'isShowHome', column),
      dataIndex: 'isShowHome',
      key: 'isShowHome',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: itemData?.data?.length > 1 ? true : false,
      render: (isShowHome) => {
        return <Checkbox disabled checked={isShowHome} />;
      },
      width: 160,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 160,
      render: (_, record) => {
        return (
          <>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Details'>
                <EditOutlined
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                  onClick={() => navigate(`/sections/manage/edit/${record.id}`)}
                />
              </Tooltip>
            )}
            <Tooltip title='View Details'>
              <InfoCircleOutlined
                onClick={() => {
                  setInfoData(record);
                  setVisible(true);
                }}
                className='font-size-20 info-btn-hover cursor-pointer mx-1'
              />
            </Tooltip>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Details'>
                <DeleteOutlined
                  onClick={() => {
                    setDeleteId(record.id);
                    setIsDeleteModalVisible(true);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {/*Inside Header*/}
      <InsideHeader className='mb-2 pb-1'>
        <Row gutter={20} className='align-items-center'>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={10}
            xl={13}
            className='gutter-row mb-1'
          >
            <Input
              placeholder='Start Typing Search Item'
              suffix={
                <SearchOutlined
                  style={{ color: '#8f9bb3' }}
                  onClick={enterSearch}
                />
              }
              onChange={(e) => handleChangeOfSearch(e)}
              onKeyPress={(e) => {
                if (e?.key === 'Enter' && e.target.value) {
                  enterSearch();
                }
              }}
              className='input-control'
              allowClear
              value={tempSearchValue}
            />
          </Col>
          {(permissions?.isCreate || permissions?.isCreate === undefined) && (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={14}
              xl={11}
              className='gutter-row mb-1'
            >
              <div className='d-flex flex-wrap align-items-center justify-content-md-left justify-content-end'>
                <ButtonCom
                  type='primary'
                  onClick={() => navigate(`/sections/manage/add/${id}`)}
                >
                  Create New Item
                </ButtonCom>
              </div>
            </Col>
          )}
        </Row>
      </InsideHeader>

      {/* Heading of Section */}
      <div>
        <div className='d-flex justify-content-between'>
          <h1 className='mb-0 font-size-28 mr-2'>
            {location?.state?.sectionName}
          </h1>
          {selectRowRecord.length > 0 && (
            <div>
              <ButtonCom
                type='primary'
                className='mr-2 width-180'
                onClick={() => handleShowHome(true)}
              >
                Show Home
              </ButtonCom>
              <ButtonCom
                type='primary'
                className='mr-2 width-180'
                onClick={() => handleShowHome(false)}
              >
                Hide Home
              </ButtonCom>
            </div>
          )}
        </div>
      </div>
      {/* Tables Data */}
      <div className='fix-table mt-2 box-shadow-3 border-radius-5'>
        <Table
          rowSelection={{
            type: <Checkbox />,
            columnWidth: 60,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={itemData.data}
          pagination={false}
          loading={loading}
          rowKey='id'
          onChange={onChange}
        />

        <div className='text-center bg-white py-2 width-1120'>
          <ButtonCom className='width-180' onClick={handleBack}>
            Back
          </ButtonCom>
        </div>

        {/* Pagination  */}
        {itemData?.total > 10 && (
          <div className='text-center bg-white pb-2 width-1120'>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={itemData?.total}
            />
          </div>
        )}
      </div>

      {/* Delete Model */}
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteModel}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />

      {/* Drawer */}
      <InfoDrawer
        setVisible={setVisible}
        visible={visible}
        infoData={infoData}
      />
    </>
  );
};

export default ShowItem;
