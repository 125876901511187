import React, { useEffect, useRef, useState } from 'react';

import { Row, Col, DatePicker, Checkbox, Input, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { ALBUMS, REPORTS } from '../../../service';

import { toast } from 'react-toastify';

import PaginationCom from '../../../components/PaginationCom';
import { tableSortIconToggle } from '../../../utility/tableSortingForSongAndAlbum';
import {
  ExportExcel,
  ExportPdf,
  handleFileName,
} from '../../../utility/Export/Export';
import Spinner from '../../../components/Spinner';
import SelectWithPagination from '../../../components/SelectWithPagination';

import { CSVLink } from 'react-csv';

const { RangePicker } = DatePicker;

const AlbumTab = () => {
  const [albumReportsList, setAlbumReportsList] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);
  const [sortedInfo, setSortedInfo] = useState({});

  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueOfRangePicker, setValueOfRangePicker] = useState(null);

  const [mostPlayed, setMostPlayed] = useState(null);
  const [favorites, setFavorites] = useState(null);

  const [spinnerValue, setSpinnerValue] = useState(false);

  const [csv, setCsv] = useState([]);
  const csvLink = useRef();

  const headers = [
    { label: 'Album Name', key: 'albumName' },
    { label: 'Subtitle', key: 'subtitle' },
    { label: 'Downloadable', key: 'isdownloadable' },
    { label: 'Favorite Count', key: 'favorite_count' },
    { label: 'Played Count', key: 'played_count' },
  ];

  const getAlbumReport = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    categoryId = null,
    subcategoryId = null,
    startDate = null,
    endDate = null
  ) => {
    setLoading(true);
    try {
      const res = await REPORTS.REPORTS_ALBUM(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        categoryId,
        subcategoryId,
        startDate,
        endDate
      );
      if (res.status === 1 || res.status === '1') {
        setAlbumReportsList(res);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const loadOptionsOfCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.GET_CATEGORY_OF_ALBUMS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.categoryName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const loadOptionsOfSubCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.GET_SUB_CATEGORY_OF_ALBUMS(
      category?.value,
      10,
      skip,
      search
    );
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.subCategoryName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const exportData = async () => {
    setSpinnerValue(true);
    try {
      const res = await REPORTS.REPORTS_ALBUM(
        '-1',
        0,
        search,
        sort,
        category?.value,
        subCategory?.value,
        startDate,
        endDate
      );
      if (res.status === 1 || res.status === '1') {
        return res;
      }
    } catch (error) {
      setSpinnerValue(false);
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setSpinnerValue(false);
    }
  };

  const generatePDF = async () => {
    let res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const pdftableColumn = [
        'Albums Title',
        'Subtitle',
        'Downloadable',
        'Favorite Count',
        'Favorite Count',
      ];
      const pdftableRows = [];
      res?.data.forEach((album) => {
        const albumReportsListData = [
          album.albumName,
          album.subtitle,
          album.isdownloadable,
          album.favorite_count,
          album.played_count,
        ];
        pdftableRows.push(albumReportsListData);
      });
      ExportPdf(pdftableColumn, pdftableRows, 'albumReport');
    }
  };

  const generateExcel = async () => {
    const res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data.map((item) => {
        return {
          'Album Name': item.albumName,
          Subtitle: item.subtitle,
          Downloadable: item.isdownloadable,
          'Favorite Count': item.favorite_count,
          'Played Count': item.played_count,
        };
      });
      ExportExcel(temp, 'albumReport');
    }
  };

  const handleDownloadCsv = async () => {
    const res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data.map((item) => {
        return {
          albumName: item.albumName,
          subtitle: item.subtitle,
          isdownloadable: item.isdownloadable,
          favorite_count: item.favorite_count,
          played_count: item.played_count,
        };
      });
      setCsv([...temp]);
      csvLink.current.link.click();
    }
  };

  const handleSorting = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
      setFavorites(false);
      setMostPlayed(false);
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
      if (sorter?.field === 'favorite_count') {
        setFavorites(true);
      }
      if (sorter?.field === 'played_count') {
        setMostPlayed(true);
      }
    } else {
      setSort({ createdAt: -1 });
      if (sorter?.field === 'favorite_count') {
        setFavorites(false);
      }
      if (sorter?.field === 'played_count') {
        setMostPlayed(false);
      }
    }
  };

  const handleChangeOfMostFavoriteAlbums = (e) => {
    setCurrentPage(1);
    setFavorites(e.target.checked);
    setMostPlayed(false);
    if (e.target.checked) {
      setSort({
        favorite_count: -1,
      });
      setSortedInfo({
        order: 'descend',
        columnKey: 'favorite_count',
      });
    } else {
      setSort({ createdAt: -1 });
      setSortedInfo({});
    }
  };

  const handleChangeOfMostPlayed = (e) => {
    setCurrentPage(1);
    setMostPlayed(e.target.checked);
    setFavorites(false);
    if (e.target.checked) {
      setSort({
        played_count: -1,
      });
      setSortedInfo({
        order: 'descend',
        columnKey: 'played_count',
      });
    } else {
      setSort({ createdAt: -1 });
      setSortedInfo({});
    }
  };

  const handleChangeOfSearch = (e) => {
    if (!e?.target?.value) {
      setSearch(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const enterSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const handleChangeOfRangePicker = (e) => {
    setCurrentPage(1);
    setValueOfRangePicker(e);
    if (e) {
      setStartDate(
        new Date(e[0]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
      setEndDate(
        new Date(e[1]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const clearAllFilter = () => {
    setCurrentPage(1);
    setPageSize(10);
    setSearch(null);
    setTempSearch(null);
    setSort(null);
    setStartDate(null);
    setEndDate(null);
    setValueOfRangePicker(null);
    setMostPlayed(null);
    setFavorites(null);
    setSortedInfo({});
    setCategory(null);
    setSubCategory(null);
  };

  useEffect(() => {
    getAlbumReport(
      currentPage,
      pageSize,
      search,
      sort,
      category?.value,
      subCategory?.value,
      startDate,
      endDate
    );
  }, [
    category?.value,
    currentPage,
    endDate,
    search,
    pageSize,
    sort,
    startDate,
    subCategory?.value,
  ]);

  const columns = [
    {
      title: (column) =>
        tableSortIconToggle('Albums Title', 'albumName', column),
      dataIndex: 'albumName',
      key: 'albumName',
      sortDirections: ['ascend', 'descend'],
      sorter: albumReportsList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'albumName' ? sortedInfo.order : undefined,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggle('Subtitle', 'subtitle', column),
      dataIndex: 'subtitle',
      key: 'subtitle',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumReportsList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'subtitle' ? sortedInfo.order : undefined,
      ellipsis: true,
    },
    {
      title: (column) =>
        tableSortIconToggle('Downloadable', 'isdownloadable', column),
      dataIndex: 'isdownloadable',
      key: 'isdownloadable',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumReportsList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'isdownloadable'
          ? sortedInfo.order
          : undefined,
      render: (_, record) => {
        return <Checkbox disabled checked={record.isdownloadable} />;
      },
      width: 170,
    },
    {
      title: (column) =>
        tableSortIconToggle('Favorite Count', 'favorite_count', column),
      key: 'favorite_count',
      dataIndex: 'favorite_count',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumReportsList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'favorite_count'
          ? sortedInfo.order
          : undefined,
      width: 170,
    },
    {
      title: (column) =>
        tableSortIconToggle('Played Count', 'played_count', column),
      key: 'played_count',
      dataIndex: 'played_count',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumReportsList?.data?.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'played_count' ? sortedInfo.order : undefined,
      width: 160,
    },
  ];

  return (
    <>
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <div className='bg-white box-shadow-3 border-radius-5 px-3 pt-3 pb-1'>
            <Row gutter={20} className='align-items-center'>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <Input
                  type='search'
                  placeholder='Start Typing to find Albums'
                  onChange={(e) => handleChangeOfSearch(e)}
                  onKeyPress={(e) => {
                    if (e?.key === 'Enter' && e.target.value) {
                      enterSearch();
                    }
                  }}
                  suffix={
                    <SearchOutlined
                      style={{ color: '#8f9bb3' }}
                      onClick={enterSearch}
                    />
                  }
                  className='input-control'
                  value={tempSearch}
                  allowClear
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <SelectWithPagination
                  placeholder='Select Category'
                  loadOptions={loadOptionsOfCategory}
                  onChange={(e) => {
                    setCategory(e);
                    setSubCategory(null);
                  }}
                  additional={{
                    skip: 0,
                  }}
                  value={category}
                  isClearable
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <SelectWithPagination
                  key={category?.value}
                  placeholder='Select Sub-Category'
                  loadOptions={loadOptionsOfSubCategory}
                  onChange={(e) => setSubCategory(e)}
                  additional={{
                    skip: 0,
                  }}
                  value={subCategory}
                  isClearable
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <RangePicker
                  className='input-control w-100'
                  onChange={(e) => handleChangeOfRangePicker(e)}
                  value={valueOfRangePicker}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <div className='d-flex'>
                  <h2 className='font-size-16 mb-0 mr-1'>
                    Most Favourite Albums
                  </h2>
                  <Checkbox
                    onChange={(e) => handleChangeOfMostFavoriteAlbums(e)}
                    checked={favorites}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <div className='d-flex'>
                  <h2 className='font-size-16 mb-0 mr-1'>Most Played</h2>
                  <Checkbox
                    onChange={(e) => handleChangeOfMostPlayed(e)}
                    checked={mostPlayed}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className='gutter-row mb-1'
              >
                <div className='d-flex flex-wrap align-items-center'>
                  <div className='mb-1 mr-2 mt-1 bg-white font-weight-600'>
                    Export
                  </div>
                  <div>
                    <button
                      className='mb-1 bg-light-gray font-weight-600 border-0 border-radius-3 py-8px px-2 mr-1 cursor-pointer'
                      onClick={handleDownloadCsv}
                      disabled={spinnerValue}
                    >
                      CSV
                    </button>
                    <CSVLink
                      data={csv}
                      headers={headers}
                      filename={handleFileName('albumReport', 'csv')}
                      ref={csvLink}
                      className='text-black font-weight-600'
                    ></CSVLink>
                  </div>
                  <button
                    className='mb-1 bg-light-gray font-weight-600 border-0 border-radius-3 py-8px px-2 mr-1 cursor-pointer'
                    onClick={generateExcel}
                    disabled={spinnerValue}
                  >
                    Excel
                  </button>
                  <button
                    onClick={() => generatePDF()}
                    disabled={spinnerValue}
                    className='mb-1 bg-light-gray font-weight-600 border-0 border-radius-3 py-8px px-2 mr-1 cursor-pointer'
                  >
                    Pdf
                  </button>
                  <h3 className='clear-all mt-1' onClick={clearAllFilter}>
                    Clear All
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* Tables Data */}
      <div className='fix-table mt-3 box-shadow-3 border-radius-5'>
        <div>
          <Table
            columns={columns}
            dataSource={albumReportsList?.data}
            pagination={false}
            rowKey='id'
            loading={loading}
            onChange={handleSorting}
          />
        </div>
        {/* Pagination */}
        {albumReportsList?.total > 10 && (
          <div className='text-center bg-white py-2 width-1120 '>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={albumReportsList?.total}
            />
          </div>
        )}

        {spinnerValue && <Spinner />}
      </div>
    </>
  );
};

export default AlbumTab;
