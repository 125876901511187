import React from 'react';

import { Checkbox, Form, Input } from 'antd';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { LOGIN } from '../../service/index';

import JalsoLogoImage from '../../assets/image/jalso-logo.svg';
import LoginImage from '../../assets/image/login.svg';

import { useDispatch } from 'react-redux';

import ButtonCom from '../../components/Button/ButtonCom';

import { ADD_TOKEN, ADD_USER } from '../../redux/features/userSlice';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (value) => {
    try {
      const login = {
        username: value.username,
        password: value.password,
        strategy: 'local',
      };
      const res = await LOGIN(login);
      if (res.status === '1' || res.status === 1) {
        localStorage.setItem('user', JSON.stringify(res.data.user));
        localStorage.setItem('jalso-token', res.data.accessToken);
        dispatch(ADD_USER(res.data.user));
        dispatch(ADD_TOKEN(res.data.accessToken));
        navigate(
          routingPath(res?.data?.user?.role?.permissions[0]?.page?.value)
        );
        toast.success('Login Successfully !');
      } else if (res.status === '0' || res.status === 0) {
        toast.error(res.message || 'Something went wrong!');
      }
    } catch (err) {
      toast.error(err.message || 'Something went wrong!');
    }
  };

  const routingPath = (path) => {
    if (path) {
      if (path === 'app_users') {
        return '/app-users';
      } else if (path === 'admin_users') {
        return '/admin-users';
      } else {
        return `/${path}`;
      }
    } else {
      return '/dashboard';
    }
  };

  return (
    <div className='login'>
      <div className='container'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='left__img'>
            <div className='jalso__logo'>
              <img src={JalsoLogoImage} alt='Jalso-logo' />
            </div>
            <img src={LoginImage} alt='img' width='100%' />
          </div>
          <div className='login-box'>
            <Form onFinish={onSubmit} layout='vertical'>
              <h3 className='title'>Jalso Admin Login</h3>
              <div>
                <Form.Item
                  label='Username'
                  name='username'
                  rules={[
                    { required: true, message: 'Username is required !' },
                  ]}
                >
                  <Input
                    placeholder='Username'
                    className='input-control w-100'
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name='password'
                  label='Password'
                  rules={[
                    { required: true, message: 'Password is required !' },
                  ]}
                >
                  <Input.Password
                    type='password'
                    placeholder='Password'
                    className='input-control w-100'
                    allowClear
                  />
                </Form.Item>
                <ButtonCom type='primary' htmlType='submit' className='w-100'>
                  Login
                </ButtonCom>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
