import { useEffect, useState } from 'react';

import PaginationCom from '../../components/PaginationCom';
import InsideHeader from '../../components/InsideHeader/InsideHeader';
import InfoDrawer from './InfoDrawer/InfoDrawer';
import DeleteModel from '../../components/DeleteModel';
import ButtonCom from '../../components/Button/ButtonCom';
import TableImage from '../../components/Image/TableImage';

import { Table, Input, Checkbox, Tooltip } from 'antd';
import {
  EditOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';

import { ADVERTISE } from '../../service';

import { tableSortIconToggle } from '../../utility/tableSorting';

import { toast } from 'react-toastify';

const Advertise = ({ permissions }) => {
  const [advertiseData, setAdvertiseData] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [infoSliderVisible, setInfoSliderVisible] = useState(false);
  const [infoSliderContent, setInfoSliderContent] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();

  const fetchAdvertise = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null
  ) => {
    setTableLoading(true);
    try {
      const res = await ADVERTISE.GET_ADVERTISE(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort
      );
      if (res.status === 1 || res.status === '1') {
        setAdvertiseData(res);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || 'Something went wrong!');
    } finally {
      setTableLoading(false);
    }
  };

  const deleteAdvertise = async () => {
    try {
      const res = await ADVERTISE.DELETE_ADVERTISE(deleteId);
      if (res.status === 1 || res.status === '1') {
        setIsDeleteModalVisible(false);
        setDeleteId(null);
        // If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (advertiseData?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchAdvertise(page, pageSize, searchValue, sort);
        }
        toast.success(res?.message);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const searchAdvertise = () => {
    setCurrentPage(1);
    setSearchValue(tempSearch);
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e?.target?.value);
    if (!e?.target?.value) {
      setSearchValue(null);
      setTempSearch(null);
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchAdvertise(page, pageSize, searchValue, sort);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  useEffect(() => {
    fetchAdvertise(currentPage, pageSize, searchValue, sort);
  }, [currentPage, pageSize, searchValue, sort]);

  const columns = [
    {
      title: 'Cover Image',
      dataIndex: 'media_item',
      key: 'media_item',
      width: 130,
      render: (media_item) => <TableImage image={media_item?.image} />,
    },
    {
      title: (column) => tableSortIconToggle('Title', 'title', column),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: advertiseData?.data?.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggle('Click URL', 'url', column),
      dataIndex: 'url',
      key: 'url',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: advertiseData?.data?.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggle('Active', 'isActivate', column),
      dataIndex: 'isActivate',
      key: 'isActivate',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: advertiseData?.data?.length > 1 ? true : false,
      width: 120,
      render: (isActivate) => {
        return <Checkbox disabled checked={isActivate} />;
      },
    },
    {
      title: (column) =>
        tableSortIconToggle('Android', 'platformAndroid', column),
      dataIndex: 'platformAndroid',
      key: 'platformAndroid',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: advertiseData?.data?.length > 1 ? true : false,
      width: 120,
      render: (platformAndroid) => {
        return <Checkbox disabled checked={platformAndroid} />;
      },
    },
    {
      title: (column) => tableSortIconToggle('iOS', 'platformIPhone', column),
      dataIndex: 'platformIPhone',
      key: 'platformIPhone',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: advertiseData?.data?.length > 1 ? true : false,
      width: 100,
      render: (platformIPhone) => {
        return <Checkbox disabled checked={platformIPhone} />;
      },
    },
    {
      title: (column) => tableSortIconToggle('Displayed', 'displayed', column),
      key: 'displayed',
      dataIndex: 'displayed',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: advertiseData?.data?.length > 1 ? true : false,
      width: 130,
    },
    {
      title: (column) => tableSortIconToggle('Clicked', 'clicked', column),
      key: 'clicked',
      dataIndex: 'clicked',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: advertiseData?.data?.length > 1 ? true : false,
      width: 120,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 160,
      render: (item, record) => {
        return (
          <div>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Advertise'>
                <EditOutlined
                  onClick={() => navigate(`/advertise/edit/${record?.id}`)}
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
            <Tooltip title='View Details'>
              <InfoCircleOutlined
                onClick={() => {
                  setInfoSliderContent(record);
                  setInfoSliderVisible(true);
                }}
                className='font-size-20 info-btn-hover cursor-pointer mx-1'
              />
            </Tooltip>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Album'>
                <DeleteOutlined
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <InsideHeader className='search-section'>
        <Input
          placeholder='Start typing to Search Advertise'
          suffix={
            <SearchOutlined
              style={{ color: '#8f9bb3' }}
              onClick={searchAdvertise}
            />
          }
          className='input-control'
          type='search'
          onChange={(e) => handleChangeOfSearch(e)}
          onKeyPress={(e) => {
            if (e?.key === 'Enter' && e.target.value) {
              searchAdvertise();
            }
          }}
          value={tempSearch}
          allowClear
        />
        <div className='search-section__btn-group'>
          {(permissions?.isCreate || permissions?.isCreate === undefined) && (
            <ButtonCom
              type='primary'
              onClick={() => navigate('/advertise/add')}
            >
              Create New Advertise
            </ButtonCom>
          )}
        </div>
      </InsideHeader>

      {/* Table */}
      <div className='fix-table mt-3 border-radius-5 box-shadow-3'>
        <Table
          columns={columns}
          dataSource={advertiseData?.data}
          pagination={false}
          rowKey='id'
          loading={tableLoading}
          onChange={onChange}
        />

        {/* Pagination  */}
        {advertiseData?.total > 10 && (
          <div className='text-center bg-white p-3 width-1120'>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={advertiseData?.total}
            />
          </div>
        )}
      </div>

      {/* Delete model */}
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteAdvertise}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />

      {/* Info slider */}
      <InfoDrawer
        setInfoSliderVisible={setInfoSliderVisible}
        infoSliderVisible={infoSliderVisible}
        infoSliderContent={infoSliderContent}
      />
    </>
  );
};

export default Advertise;
