import { useState, useEffect } from 'react';

import InsideHeader from '../../../components/InsideHeader/InsideHeader';
import DeleteModel from '../../../components/DeleteModel';
import PaginationCom from '../../../components/PaginationCom';
import InfoDrawer from './InfoDrawer/InfoDrawer';
import TableImage from '../../../components/Image/TableImage';
import SelectWithPagination from '../../../components/SelectWithPagination';
import ButtonCom from '../../../components/Button/ButtonCom';

import { Table, Input, Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  FilterFilled,
} from '@ant-design/icons';

import { CATEGORY, SUB_CATEGORY } from '../../../service';

import { useNavigate } from 'react-router-dom';

import { tableSortIconToggle } from '../../../utility/tableSorting';

import { toast } from 'react-toastify';

const SubCategory = ({ permissions }) => {
  const [subCategory, setSubCategory] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [infoSliderVisible, setInfoSliderVisible] = useState(false);
  const [infoSliderContent, setInfoSliderContent] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);

  const [category, setCategory] = useState(null);

  const [tableDataLength, setTableDataLength] = useState(0);

  const [filterCardShow, setFilterCardShow] = useState(false);

  const navigate = useNavigate();

  const fetchSubCategory = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    categoryId = null
  ) => {
    setTableLoading(true);
    try {
      const res = await SUB_CATEGORY.GET_SUB_CATEGORY(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        categoryId
      );
      if (res.status === 1 || res.status === '1') {
        setSubCategory(res);
        setTableDataLength(res?.data && res?.data.length);
      } else {
        throw res;
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
    setTableLoading(false);
  };

  const loadOptionsOfCategory = async (search, prevOptions, { skip }) => {
    const res = await CATEGORY.GET_CATEGORY(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.categoryName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const deleteSubCategory = async () => {
    try {
      const res = await SUB_CATEGORY.DELETE_SUB_CATEGORY(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        toast.success(res?.message);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (subCategory?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchSubCategory(page, pageSize, search, sort, category?.value);
        }
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const searchSubCategory = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  function onChange(pagination, filters, sorter, extra) {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  }

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchSubCategory(currentPage, pageSize, search, sort, category?.value);
  }, [category?.value, currentPage, pageSize, search, sort]);

  const columns = [
    {
      title: 'Cover Image',
      dataIndex: 'media_item',
      key: 'media_item',
      render: (media_item) => <TableImage image={media_item?.image} />,
      width: 130,
    },
    {
      title: (column) =>
        tableSortIconToggle(
          'Sub-Category',
          'subCategoryName',
          column,
          tableDataLength
        ),
      dataIndex: 'subCategoryName',
      key: 'subCategoryName',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: tableDataLength > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) =>
        tableSortIconToggle('Subtitle', 'subtitle', column, tableDataLength),
      dataIndex: 'subtitle',
      key: 'subtitle',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: tableDataLength > 1 ? true : false,
      ellipsis: true,
      render: (subtitle) => (subtitle ? subtitle : '-'),
    },
    {
      title: 'Parent Sub-Category',
      dataIndex: 'parentName',
      key: 'parentName',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
      render: (parentName) => (parentName ? parentName : '-'),
    },
    {
      title: 'category',
      dataIndex: 'category',
      key: 'category',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
      render: (category) => (category ? category?.categoryName : '-'),
    },
    {
      title: (column) =>
        tableSortIconToggle(
          'Position No',
          'positionNo',
          column,
          tableDataLength
        ),
      dataIndex: 'positionNo',
      key: 'positionNo',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: tableDataLength > 1 ? true : false,
      width: 150,
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 160,
      render: (_, record) => {
        return (
          <>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Sub-Category'>
                <EditOutlined
                  onClick={() =>
                    navigate(`/category/sub-category/edit/${record?.id}`)
                  }
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}

            <Tooltip title='View Details'>
              <InfoCircleOutlined
                className='font-size-20 info-btn-hover cursor-pointer mx-1'
                onClick={() => {
                  setInfoSliderContent(record);
                  setInfoSliderVisible(true);
                }}
              />
            </Tooltip>

            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Sub-Category'>
                <DeleteOutlined
                  onClick={() => {
                    setDeleteId(record?.id);
                    setIsDeleteModalVisible(true);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <InsideHeader className='search-section pb-1'>
        <Input
          placeholder='Start Typing Search Sub-Category'
          onChange={(e) => {
            if (!e?.target?.value) {
              setSearch(null);
              setTempSearch(null);
            }
            setTempSearch(e?.target?.value);
          }}
          onKeyPress={(e) => {
            if (e?.key === 'Enter' && e.target.value) {
              searchSubCategory();
            }
          }}
          suffix={
            <SearchOutlined
              onClick={searchSubCategory}
              style={{ color: '#8f9bb3' }}
            />
          }
          className='input-control mb-1'
          allowClear
        />
        <div className='search-section__btn-group'>
          {(permissions?.isCreate || permissions?.isCreate === undefined) && (
            <ButtonCom
              type='primary'
              className='mb-1 mr-1 width-180'
              onClick={() => navigate('/category/sub-category/add')}
            >
              <span style={{ fontSize: '15px' }}>
                Create New
                <span style={{ display: 'block', marginTop: '-10px' }}>
                  Sub-Category
                </span>
              </span>
            </ButtonCom>
          )}
          <ButtonCom
            type='primary'
            className='mb-1 mr-1'
            onClick={() => navigate('/category/sub-category/album')}
          >
            Album Sub-Category
          </ButtonCom>
          <ButtonCom
            type='primary'
            className='mb-1 '
            onClick={() => setFilterCardShow(!filterCardShow)}
          >
            <div className='d-flex align-items-center'>
              Sub-Category Filter
              <FilterFilled className='ml-1' />
            </div>
          </ButtonCom>
        </div>
      </InsideHeader>

      {/* Filter */}
      {filterCardShow && (
        <InsideHeader className='mt-3'>
          <SelectWithPagination
            placeholder='Select Category'
            loadOptions={loadOptionsOfCategory}
            onChange={(e) => setCategory(e)}
            additional={{
              skip: 0,
            }}
            className='width-250'
            isClearable
          />
        </InsideHeader>
      )}

      <div className='fix-table mt-3 box-shadow-3 border-radius-5'>
        <Table
          columns={columns}
          loading={tableLoading}
          dataSource={subCategory?.data}
          pagination={false}
          rowKey='id'
          onChange={onChange}
        />

        <div className='text-center bg-white py-2 width-1120'>
          <ButtonCom
            className='width-180'
            onClick={() => navigate('/category')}
          >
            Back
          </ButtonCom>
        </div>

        {subCategory?.total > 10 && (
          <div className='text-center bg-white pb-2 width-1120'>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={subCategory?.total}
            />
          </div>
        )}
      </div>

      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteSubCategory}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />

      <InfoDrawer
        setInfoSliderVisible={setInfoSliderVisible}
        infoSliderVisible={infoSliderVisible}
        infoSliderContent={infoSliderContent}
      />
    </>
  );
};

export default SubCategory;
