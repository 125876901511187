import { methods, serviceMaker } from '../service';

const GET_CATEGORY = (limit, skip, search, sort) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`category`, methods.GET, query);
};

const GET_CATEGORY_BY_ID = (id) => serviceMaker(`category/${id}`, methods.GET);

const ADD_CATEGORY = (payload) =>
  serviceMaker(`category`, methods.POST, null, payload);

const EDIT_CATEGORY = (id, payload) =>
  serviceMaker(`category/${id}`, methods.PATCH, null, payload);

const DELETE_CATEGORY = (id) => serviceMaker(`category/${id}`, methods.DELETE);

export const CATEGORY = {
  GET_CATEGORY,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORY_BY_ID,
};

const GET_SUB_CATEGORY = (
  limit,
  skip,
  search = null,
  sort = null,
  categoryId
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (categoryId) {
    query['params']['categoryId'] = categoryId;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`subcategory`, methods.GET, query);
};

const GET_SUB_CATEGORY_BY_ID = (id) =>
  serviceMaker(`subcategory/${id}`, methods.GET);

const ADD_SUB_CATEGORY = (payload) =>
  serviceMaker(`subcategory`, methods.POST, null, payload);

const EDIT_SUB_CATEGORY = (id, payload) =>
  serviceMaker(`subcategory/${id}`, methods.PATCH, null, payload);

const DELETE_SUB_CATEGORY = (id) =>
  serviceMaker(`subcategory/${id}`, methods.DELETE);

export const SUB_CATEGORY = {
  GET_SUB_CATEGORY,
  GET_SUB_CATEGORY_BY_ID,
  ADD_SUB_CATEGORY,
  EDIT_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
};
