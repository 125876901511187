import React, { useState } from 'react';

import { Row, Col, Steps } from 'antd';

import SelectedAlbum from './Steps/SelectedAlbum';
import AddSong from '../../../components/AddSong';
import ReviewData from '../../../components/ReviewData';

const { Step } = Steps;

const AddSongs = () => {
  const [current, setCurrent] = useState(0);

  return (
    <>
      {/* Step */}
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <Steps current={current}>
            <Step title='Select Album' />
            <Step title='Add Songs' />
            <Step title='Review Data' />
          </Steps>
        </Col>
      </Row>

      {/* Selected Album */}
      {current === 0 && <SelectedAlbum setCurrent={setCurrent} />}

      {/* add songs */}
      {current === 1 && <AddSong setCurrent={setCurrent} />}

      {/* Review Data */}
      {current === 2 && <ReviewData setCurrent={setCurrent} />}
    </>
  );
};

export default AddSongs;
