import React, { useEffect, useState } from 'react';

import { Row, Col, Input, Table, Checkbox, Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  FilterFilled,
} from '@ant-design/icons';

import { ALBUMS, SONGS } from '../../service';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import DeleteModel from '../../components/DeleteModel';
import PaginationCom from '../../components/PaginationCom';
import SelectWithPagination from '../../components/SelectWithPagination';
import ButtonCom from '../../components/Button/ButtonCom';
import InfoDrawer from './InfoDrawer/InfoDrawer';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { tableSortIconToggle } from '../../utility/tableSorting';

const Songs = ({ permissions }) => {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);

  const [songType, setSongType] = useState(null);
  const [artist, setArtist] = useState(null);
  const [album, setAlbum] = useState(null);
  const [downloadable, setDownloadable] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [infoDeatils, setInfoDeatils] = useState({});
  const [visible, setVisible] = useState(false);

  const [isshowFilter, setIsShowFilter] = useState(false);

  const navigate = useNavigate();

  const fetchSongs = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    songType = null,
    isdownloadable = null,
    artistId = null,
    albumId = null
  ) => {
    setLoading(true);
    try {
      const res = await SONGS.GET_SONG(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        songType,
        isdownloadable,
        artistId,
        albumId
      );
      const resetData = res?.data?.map((item) => {
        const obj = [];
        item.song_artists.forEach((artist) => {
          if (artist.artist) {
            const filterArtist = obj.filter((f) => f.id === artist?.artist?.id);
            if (obj.length > 0 && filterArtist.length > 0) {
              filterArtist[0].artist_added_roles.push(artist.artist_role);
            } else {
              let artistObj = {
                ...artist.artist,
                artist_added_roles: [artist.artist_role],
              };
              obj.push(artistObj);
            }
          }
        });

        delete item.song_artists;
        return { ...item, song_artists: obj };
      });
      if (res.status === 1 || res.status === '1') {
        setSongs({ total: res.total, data: resetData });
      }
    } catch (err) {
      toast.error(err.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const loadOptionsOfSongType = async (search, prevOptions) => {
    const res = await SONGS.GET_CHOOSE_INTEREST();
    const option = res?.data.map((item) => {
      return { value: item?.id, label: item?.name };
    });
    return {
      options: option,
    };
  };

  const loadOptionsOfArtist = async (search, prevOptions, { skip }) => {
    const res = await SONGS.SEARCH_ARTIST(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const loadOptionsOfAlbum = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.ALL_ALBUMS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.albumName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const deleteModel = async () => {
    try {
      const res = await SONGS.REMOVE_SONG(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        setIsDeleteModalVisible(false);
        let page = currentPage;
        if (songs?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchSongs(
            page,
            pageSize,
            search,
            sort,
            songType?.label,
            downloadable,
            artist?.value,
            album?.value
          );
        }
        toast.success(res?.message);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSorting = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  useEffect(() => {
    fetchSongs(
      currentPage,
      pageSize,
      search,
      sort,
      songType?.label,
      downloadable,
      artist?.value,
      album?.value
    );
  }, [
    album?.value,
    artist?.value,
    currentPage,
    downloadable,
    pageSize,
    search,
    songType?.label,
    sort,
  ]);

  const columns = [
    {
      title: (column) => tableSortIconToggle('Song Title', 'songTitle', column),
      dataIndex: 'songTitle',
      key: 'songTitle',
      sortDirections: ['ascend', 'descend'],
      align: 'left',
      sorter: songs?.data?.length > 1 ? true : false,
      ellipsis: true,
      render: (songTitle) => <span>{songTitle}</span>,
    },
    {
      title: (column) => tableSortIconToggle('CMS Path', 'cmsPath', column),
      dataIndex: 'cmsPath',
      key: 'cmsPath',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songs?.data?.length > 1 ? true : false,
      ellipsis: true,
      render: (cmsPath) => <span>{cmsPath}</span>,
    },
    {
      title: (column) => tableSortIconToggle('Mother Album', 'album', column),
      dataIndex: 'album',
      key: 'album',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songs?.data?.length > 1 ? true : false,
      ellipsis: true,
      render: (album) => <span>{album?.albumName}</span>,
    },
    {
      title: (column) => tableSortIconToggle('Song Type', 'songType', column),
      dataIndex: 'songType',
      key: 'songType',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songs?.data?.length > 1 ? true : false,
      width: 150,
    },
    {
      title: (column) => tableSortIconToggle('Duration', 'duration', column),
      dataIndex: 'duration',
      key: 'duration',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songs?.data?.length > 1 ? true : false,
      width: 120,
    },
    {
      title: (column) =>
        tableSortIconToggle('Downloadable', 'isdownloadable', column),
      dataIndex: 'isdownloadable',
      key: 'isdownloadable',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songs?.data?.length > 1 ? true : false,
      render: (_, record) => (
        <Checkbox disabled checked={record?.isdownloadable}></Checkbox>
      ),
      width: 170,
    },
    {
      title: 'Bits',
      align: 'center',
      width: 120,
      render: (_, record) => {
        return (
          <>
            <Row gutter={10}>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
                <Tooltip title='32 Bits File'>
                  <Checkbox
                    disabled
                    checked={record?.media_sound?.bitFile32}
                  ></Checkbox>
                </Tooltip>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
                <Tooltip title='64 Bits File'>
                  <Checkbox
                    disabled
                    checked={record?.media_sound?.bitFile64}
                  ></Checkbox>
                </Tooltip>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
                <Tooltip title='128 Bits File'>
                  <Checkbox
                    disabled
                    checked={record?.media_sound?.bitFile128}
                  ></Checkbox>
                </Tooltip>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
                <Tooltip title='256 Bits File'>
                  <Checkbox
                    disabled
                    checked={record?.media_sound?.bitFile256}
                  ></Checkbox>
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 160,
      render: (_, record) => {
        return (
          <>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Song'>
                <EditOutlined
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                  onClick={() => navigate(`/songs/edit/${record.id}`)}
                />
              </Tooltip>
            )}
            <Tooltip title='View Song'>
              <InfoCircleOutlined
                className='font-size-20 info-btn-hover cursor-pointer mx-1'
                onClick={() => {
                  setVisible(true);
                  setInfoDeatils(record);
                }}
              />
            </Tooltip>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Song'>
                <DeleteOutlined
                  onClick={() => {
                    setDeleteId(record.id);
                    setIsDeleteModalVisible(true);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <InsideHeader className='px-2 pt-2 pb-1'>
        <Input
          placeholder='Start Typing Search Song'
          suffix={
            <SearchOutlined
              style={{ color: '#8f9bb3' }}
              onClick={() => setSearch(tempSearch)}
            />
          }
          onChange={(e) => {
            if (!e.target.value) {
              setCurrentPage(1);
              setSearch(null);
              setTempSearch(null);
            } else {
              setTempSearch(e.target.value);
            }
          }}
          onKeyPress={(e) => {
            if (e?.key === 'Enter' && e.target.value) {
              setCurrentPage(1);
              setSearch(tempSearch);
            }
          }}
          className='input-control mb-1'
          value={tempSearch}
          allowClear
        />
        <div>
          {(permissions?.isCreate || permissions?.isCreate === undefined) && (
            <ButtonCom
              type='primary'
              className='mr-1 mb-1'
              onClick={() => {
                navigate('/songs/add');
                localStorage.removeItem('step1');
                localStorage.removeItem('step2');
                localStorage.removeItem('file');
              }}
            >
              Add New Songs
            </ButtonCom>
          )}
          <ButtonCom
            type='primary'
            className='mr-1 mb-1'
            onClick={() => navigate('/songs/search')}
          >
            Search Song in Albums
          </ButtonCom>
          {(permissions?.isCreate || permissions?.isCreate === undefined) && (
            <ButtonCom
              type='primary'
              className='mr-1 mb-1'
              onClick={() => {
                navigate('/songs/add-album');
                localStorage.removeItem('add-album-1');
                localStorage.removeItem('song-record');
                localStorage.removeItem('add-album-2');
                localStorage.removeItem('album-record');
                localStorage.removeItem('add-album-already');
              }}
            >
              Add Song in Albums
            </ButtonCom>
          )}
          <ButtonCom
            type='primary'
            className='mr-1 mb-1'
            onClick={() => setIsShowFilter(!isshowFilter)}
          >
            <div className='d-flex align-items-center'>
              Song Filters
              <FilterFilled className='ml-1' />
            </div>
          </ButtonCom>
        </div>
      </InsideHeader>

      {isshowFilter && (
        <InsideHeader className='pt-2 pb-1 mt-3'>
          <Row gutter={20} className='align-items-center'>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <SelectWithPagination
                placeholder='Select Song Type'
                loadOptions={loadOptionsOfSongType}
                onChange={(e) => setSongType(e)}
                value={songType}
                isClearable
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <SelectWithPagination
                placeholder='Select Artist'
                loadOptions={loadOptionsOfArtist}
                onChange={(e) => setArtist(e)}
                additional={{
                  skip: 0,
                }}
                value={artist}
                isClearable
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <SelectWithPagination
                placeholder='Select Album'
                loadOptions={loadOptionsOfAlbum}
                onChange={(e) => setAlbum(e)}
                additional={{
                  skip: 0,
                }}
                value={album}
                isClearable
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <div className='d-flex'>
                <h2 className='font-size-16 mb-0 mr-1'>Downloadable</h2>
                <Checkbox
                  checked={downloadable}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setDownloadable(e.target.checked);
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} className='gutter-row'>
              <h3
                className='clear-all'
                onClick={() => {
                  setCurrentPage(1);
                  setPageSize(10);
                  setSongType(null);
                  setArtist(null);
                  setAlbum(null);
                  setDownloadable(null);
                }}
              >
                Clear All
              </h3>
            </Col>
          </Row>
        </InsideHeader>
      )}

      <div className='fix-table box-shadow-3 mt-3 border-radius-5'>
        <Table
          dataSource={songs?.data}
          columns={columns}
          className='border-radius-5'
          pagination={false}
          loading={loading}
          rowKey='id'
          onChange={handleSorting}
        />

        {songs?.total > 10 && (
          <div className='text-center bg-white p-3 width-1120 '>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={songs?.total}
            />
          </div>
        )}

        {visible && (
          <InfoDrawer
            visible={visible}
            setVisible={setVisible}
            infoDeatils={infoDeatils}
            setInfoDeatils={setInfoDeatils}
          />
        )}

        <DeleteModel
          isDeleteModalVisible={isDeleteModalVisible}
          deleteModel={deleteModel}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
        />
      </div>
    </>
  );
};

export default Songs;
